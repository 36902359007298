import { register } from "../serviceWorkerRegistration";


export const getCityInLang = (city, langCode) => {
  const cities = JSON.parse(localStorage.getItem("cities"));
  const res = cities?.filter(
    (obj) => obj?.city === city || obj?.cityKey === city
  );
  return res[0]?.city_lang?.[langCode];
};
export const getLangState = () => {
  return {
    "Andhra Pradesh": "आंध्र प्रदेश",
    "Arunachal Pradesh": "अरुणाचल प्रदेश",
    Assam: "असम",
    Bihar: "बिहार",
    "Chandigarh (UT)": "चंडीगढ़ (यूटी)",
    Chhattisgarh: "छत्तीसगढ",
    "Dadra and Nagar Haveli (UT)": "दादरा और नगर हवेली (यूटी)",
    "Daman and Diu (UT)": "दमन और दीव (यूटी)",
    Delhi: "दिल्ली",
    Goa: "गोवा",
    Gujarat: "गुजरात",
    Haryana: "हरियाणा",
    "Himachal Pradesh": "हिमाचल प्रदेश",
    Jharkhand: "झारखंड",
    "Jammu and Kashmir": "जम्मू और कश्मीर",
    Karnataka: "कर्नाटक",
    Kerala: "केरल",
    "Madhya Pradesh": "मध्य प्रदेश",
    Maharashtra: "महाराष्ट्र",
    Manipur: "मणिपुर",
    Meghalaya: "मेघालय",
    Mizoram: "मिजोरम",
    Nagaland: "नगालैंड",
    Odisha: "ओडिशा",
    "Puducherry (UT)": "पुडुचेरी (यूटी)",
    Punjab: "पंजाब",
    Rajasthan: "राजस्थान",
    Sikkim: "सिक्किम",
    "Tamil Nadu": "तमिलनाडु",
    Telangana: "तेलंगाना",
    Tripura: "त्रिपुरा",
    "Uttar Pradesh": "उत्तर प्रदेश",
    Uttarakhand: "उत्तराखंड",
    "West Bengal": "पश्चिम बंगाल",
    "Andaman & Nicobar Island": "अंडमान और निकोबार द्वीप",
    Lakshadweep: "लक्षद्वीप",
    Ladakh: "लद्दाख",
  };
};

export const getLangLabel = (lang) => {
  if (lang === "hi")
    return {
      footerSearch: "खोजें",
      search: "अपना गंतव्य खोजें",
      location: "स्थान चुनने के लिए क्लिक करें",
      locationL1: "आप यहां पर है: ",
      locationL2: "अपने आस-पास के स्थानों का पता लगाने के लिए क्लिक करें",
      discover: "अभी खोजें",
      exploreL1: "ऐसे खोजें",
      exploreL2: "भारत को",
      exploreL3: "जैसे पहले कभी नहीं देखा",
      map: "आप जहां भी एक्सप्लोर करना चाहते हैं, वहां टैप करें",
      ourFavourites: "हमारे पसंदीदा",
      footerL1: "© सभी अधिकार मनोहर अग्रवाल द्वारा सुरक्षित - चलो भारत देखें",
      footerL2: "गोपनीयता नीति",
      footerL3: "नियम और शर्तें",
      footerL4: "उपयोगकर्ता पुस्तिका",
      option1: "ब्लॉग",
      option2: "संपर्क करें",
      option3: "ऐप साझा करें",
      menu: "मेन्यू",
      menuL1: "बुकमार्क",
      menuL2: "हमारे ऐप को रेट करें",
      menuL3: "हमें अनुसरण करें",
      menuL4: "हमसे संपर्क करें",
      menuL5: "सेटिंग्स",
      menuL6: "संपर्क करें",
      menuL7: "ऐप साझा करें",
      menuFooter: "© सभी अधिकार मनोहर अग्रवाल द्वारा सुरक्षित - चलो भारत देखें",
      loginBtnLabel: "आएँ शुरू करें",
      loginContact: "संपर्क करें :",
      loginFooter:
        "© सभी अधिकार मनोहर अग्रवाल द्वारा सुरक्षित - चलो भारत देखें",
      alreadyVisited: "क्या आप पहले ही इस जगह का दौरा कर चुके हैं ?",
      review: "समीक्षा",
      reviewNotAllow: "कृपया पहले ऐप में अपने खाते में लॉग इन करें।",
      reviewReport: "समीक्षा रिपोर्ट सफलतापूर्वक भेजी गई हैं",
      reviewLabel: "समीक्षा",
      notReviews: "अभी तक कोई समीक्षा नहीं",
      cancelBtn: "रद्द करना",
      enterReview: "अपनी समीक्षा दर्ज करें",
      reviewHere: "यहां समीक्षा करें",
      addPhotos: "तस्वीरें जोडो",
      submit: "जमा करना",
      account: "खाता",
      reviewFooter: `भारत देखो ऐसी टिप्पणियाँ पोस्ट नहीं करेगा जिनमें अपमानजनक, अश्लील, अपमानजनक, धमकी देने वाली या परेशान करने वाली भाषा, किसी भी प्रकार के व्यक्तिगत हमले या विशिष्ट व्यक्तियों या समूहों को लक्षित करने वाले आक्रामक शब्द शामिल हों।`,
      nearPlaces: "निकटवर्ती स्थान",
      noNearPlaces: "आस-पास कोई स्थान उपलब्ध नहीं है",
      welcomeTo: "आपका स्वागत है",
      home: "होम",
      goToDownload: "बुकमार्क पर जाएँ",
      downloads: "बुकमार्क",
      notDownloads: "अभी तक कोई बुकमार्क नहीं",
      alreadyDownloaded: "डेटा पहले ही डाउनलोड हो चुका है।",
      dataDownloaded: "डेटा सफलतापूर्वक डाउनलोड हो गया।",
      pleaseWait: "कृपया प्रतीक्षा करें...",
      notificationDenied: "आपने अधिसूचना के लिए मना कर दिया।",
      geoPermission:
        "जियोलोकेशन तक पहुँचने की अनुमति अस्वीकृत. कृपया इस सुविधा का उपयोग करने के लिए स्थान सेवाओं को सक्षम करें।",
      dataDeleted: "डेटा सफलतापूर्वक हटा दिया गया है।",
      imagesError:
        "छवियों को संसाधित करते समय एक त्रुटि उत्पन्न हुई. कृपया पुन: प्रयास करें।",
      reviewSaved: "समीक्षा सहेजी गई.",
      chooseLanguage: "भाषा चुनें",
      notifications: "सूचनाएं",
      appVersion: "एप्लिकेशन",
      facebook: "फेसबुक",
      twitter: "ट्विटर",
      listening: "मैं सुन रहा हूँ",
      profileUpdated: "आपकी प्रोफ़ाइल सफलतापूर्वक अपडेट हो गई.",
      reviewNotEmpty: "समीक्षा खाली नहीं हो सकती.",
      profileMenu1: "प्रोफ़ाइल संपादित करें",
      profileMenu2: "पासवर्ड बदलें",
      profileMenu3: "खाता हटा दो",
      profileMenu4: "बुकिंगस",
      profileMenu5: "लॉग आउट",
      deleteAccMsg: "क्या आप इस खाते को हटाने के लिए सुनिश्चित हैं?",
      logoutMsg: "क्या आप वाकई अपना खाता लॉगआउट करना चाहते हैं?",
      yes: "हाँ",
      no: "नहीं",
      version: "वर्जन",
      myProfile: "मेरी प्रोफाइल",
      welcome: "स्वागत",
      lastLogin: "आखरी लॉगइन",
      editProfile: "प्रोफ़ाइल संपादित करें",
      name: "नाम",
      email: "ईमेल",
      phoneNumber: "फ़ोन नंबर",
      gender: "लिंग",
      male: "पुरुष",
      female: "महिला",
      others: "अन्य",
      dob: "जन्मतिथि",
      update: "अपडेट",
      changePassword: "पासवर्ड बदलें",
      oldPassword: "पुराना पासवर्ड",
      newPassword: "नया पासवर्ड",
      confirmPassword: "पासवर्ड की पुष्टि कीजिये",
      save: "सेव",
      requireFields: "सभी फ़ील्ड आवश्यक हैं।",
      notMatch: "नया पासवर्ड और पुष्टिकरण पासवर्ड मेल नहीं खाते।",
      passwordChanged: "पासवर्ड सफलतापूर्वक बदला गया।",
      confirmPasswordP: "पासवर्ड की पुष्टि कीजिये",
      newPasswordP: "आपका नया पासवर्ड",
      oldPasswordP: "आपका पुराना पासवर्ड",
      youAreBack: "आप वापस आ गए हैं!",
      loginText:
        "अपने खाते में लॉग इन करके विशेष यात्रा अनुशंसाओं और सुविधाओं तक पहुंच प्राप्त करें।",
      password: "पासवर्ड",
      yourEmailP: "आपका ईमेल पता",
      yourPasswordP: "आपका पासवर्ड",
      logIn: "लॉग इन करें",
      signUp: "साइन अप करें",
      loginP1: "जारी रखकर आप हमारी बात से सहमत हैं",
      loginP2: "नियम एवं शर्तें",
      loginP3:
        "। हम आपको व्यक्तिगत अनुभव प्रदान करने के लिए आपके डेटा का उपयोग करते हैं।",
      loginP4: "और अधिक जानकारी प्राप्त करें।",
      loginP5: "कोई खाता नहीं है?",
      signUpText:
        "विशेष अनुशंसाओं और व्यावहारिक यात्रा गाइडों तक पहुंचने के लिए साइन अप करें।",
      upload: "अपलोड करने के लिए क्लिक करें",
      yourName: "आपका नाम",
      yourPN: "आपका फोन नंबर",
      selectDOB: "अपनी जन्मतिथि चुनें",
      invalidEmail: "अमान्य ईमेल प्रारूप।",
      emailRequired: "ईमेल की जरूरत है।",
      invalidPN: "फ़ोन नंबर 10 अंकों का होना चाहिए।",
      invalidPN2: "पासवर्ड कम से कम 6 अंकों का होना चाहिए।",
      validEmail: "कृपया एक मान्य ईमेल दर्ज करें।",
      passRequired: "पासवर्ड आवश्यक है।",
      passMust: "पासवर्ड कम से कम 6 अक्षरों का होना चाहिए।",
      bookmarked: "बुकमार्क किए गए पृष्ठ ऑफ़लाइन उपयोग के लिए भी उपलब्ध हैं।",
      contents:"विषय",
      instagram:"इंस्टाग्राम",
      Linkedin:"लिंकेडीन",
      giveRating:"कृपया इस स्थान को रेटिंग दें।",
      doYouWantToReport:"क्या आप इस समीक्षा की रिपोर्ट करना चाहते हैं?",
      verify:"सत्यापित करें",
      forgotPassword:"पासवर्ड भूल गए?",
      report:"रिपोर्ट",
      delete:"हटाना",
      edit:"संपादन करें",
      emailVerificationLink:"ईमेल सत्यापन लिंक भेजा गया",
      passwordReset:"पासवर्ड रीसेट लिंक भेजा गया!",
      registerSuccesfully:"सफलतापूर्वक रजिस्टर किया गया है",
      userNotFound:"देश में नहीं मिला गया",
      passResetSuccessfully:"पासवर्ड सफलतापूर्वक रीसेट किया गया है",
      verifyEmailBeforeLogin:"पहले से ईमेल सत्यापित करें",
      emailVerified:"आपकी प्रोफ़ाइल सफलतापूर्वक अपडेट हो गई। अब आप ऐप के जरिए लॉग इन कर सकते हैं।"
    };
  else {
    return {
      footerSearch: "Search",
      search: "Search your destination",
      location: "Click to explore nearby places",
      locationL1: "You are at: ",
      locationL2: "Click to explore places near you",
      discover: "DISCOVER NOW",
      exploreL1: "EXPLORE",
      exploreL2: "INDIA",
      exploreL3: "LIKE NEVER BEFORE!",
      map: "Tap anywhere you want to explore",
      ourFavourites: "Our Favourites",
      footerL1:
        "© All rights reserved by Manohar Agarwal - Chalo Bharat Dekhen",
      footerL2: "Privacy Policy",
      footerL3: "Terms of Use",
      footerL4: "User Manual",
      option1: "Blogs",
      option2: "Contact",
      option3: "Share",
      menu: "Menu",
      menuL1: "Bookmarks",
      menuL2: "Rate our app",
      menuL3: "Follow Us",
      menuL4: "Contact Us",
      menuL5: "Settings",
      menuL6: "Contact Us",
      menuL7: "Share the app",
      menuFooter: "All rights reserved by Manohar Agarwal",
      loginBtnLabel: "Let's Get Started",
      loginContact: "Contact Us :",
      loginFooter:
        "© All rights reserved by Manohar Agarwal - Chalo Bharat Dekhen",
      alreadyVisited: "Already visited this place ?",
      review: "Review",
      reviewNotAllow: "Please log in to your account in the app first.",
      reviewReport: "Review report sent successfully.",
      doYouWantToReport: "Do you want to report this review?",
      reviewLabel: "Reviews",
      notReviews: "No reviews yet",
      cancelBtn: "Cancel",
      enterReview: "Enter Your Review",
      reviewHere: "Review here",
      addPhotos: "Add Photos",
      submit: "Submit",
      account: "Account",
      reviewFooter: `Chalo Bharat Dekhen will not post comments that contain abusive,
                      vulgar, offensive, threatening or harassing language,
                      personal attacks of any kind, or offensive terms that target
                      specific individuals or groups.`,
      nearPlaces: "Nearby Places",
      noNearPlaces: "No Nearby Places Available",
      welcomeTo: "Welcome to",
      home: "Home",
      goToDownload: "Go to bookmarks",
      downloads: "Bookmarks",
      notDownloads: "No Bookmarks yet",
      alreadyDownloaded: "Data already downloaded.",
      dataDownloaded: "Data downloaded successfully",
      pleaseWait: "Please wait...",
      notificationDenied: "You denied for the notification",
      geoPermission:
        "Permission to access geolocation denied. Please enable location services to use this feature.",
      dataDeleted: "Data deleted successfully.",
      imagesError:
        "An error occurred while processing the images. Please try again.",
      reviewSaved: "Review saved.",
      chooseLanguage: "Choose language",
      notifications: "Notifications",
      appVersion: "App Version",
      facebook: "Facebook",
      twitter: "Twitter",
      listening: "Listening",
      profileUpdated: "Your profile updated successfully.",
      reviewNotEmpty: "Review cannot be empty.",
      profileMenu1: "Edit Profile",
      profileMenu2: "Change Password",
      profileMenu3: "Delete Account",
      profileMenu4: "Bookings",
      profileMenu5: "Logout",
      deleteAccMsg: "Are you sure you want to delete your account?",
      logoutMsg: "Are you sure you want to logout your account?",
      yes: "Yes",
      no: "No",
      version: "Version",
      myProfile: "My Profile",
      welcome: "Welcome",
      lastLogin: "Last Login",
      editProfile: "Edit Profile",
      name: "Name",
      email: "Email",
      phoneNumber: "Phone Number",
      gender: "Gender",
      male: "Male",
      female: "Female",
      others: "Others",
      dob: "Date of Birth",
      update: "Update",
      changePassword: "Change Password",
      oldPassword: "Old Password",
      newPassword: "New Password",
      confirmPassword: "Confirm Password",
      save: "Save",
      requireFields: "All fields are required.",
      notMatch: "New password and confirmation password do not match.",
      passwordChanged: "Password changed successfully.",
      confirmPasswordP: "Confirm password",
      newPasswordP: "Your new password",
      oldPasswordP: "Your old password",
      youAreBack: "You're back!",
      loginText:
        "Get access to exclusive travel recommendations and features by logging in to your account.",
      password: "Password",
      yourEmailP: "Your email address",
      yourPasswordP: "Your password",
      logIn: "Log In",
      signUp: "Sign Up",
      loginP1: "By continuing you agree to our",
      loginP2: "T&Cs",
      loginP3: ". We use your data to offer you a personalized experience.",
      loginP4: "Find out more.",
      loginP5: "Don't have an account?",
      signUpText:
        "Sign up to access exclusive recommendations and insightful travel guides.",
      upload: "Click to upload",
      yourName: "Your name",
      yourPN: "Your phone number",
      selectDOB: "Select your birth date",
      invalidEmail: "Invalid email format.",
      emailRequired: "Email is required.",
      invalidPN: "Phone number must be 10 digits.",
      invalidPN2: "Password must be at least 6 characters.",
      validEmail: "Please enter a valid email.",
      verify: "Verify",
      passRequired: "Password is required.",
      passMust: "Password must be at least 6 characters long.",
      bookmarked: "Bookmarked pages are also available for offline use.",
      contents:"Contents",
      instagram:"Instagram",
      Linkedin:"Linkedin",
      giveRating:"Please give rating to this place.",
      forgotPassword:"Forgot Password?",
      report:"Report",
      delete:"Delete",
      edit:"Edit",
      emailVerificationLink:"Email verification link sent",
      passwordReset:"Password reset link sent!",
      passwordDidNotMatch:"password did not match",
      registerSuccesfully:"Register successfully",
      userNotFound:"User Not Found",
      passResetSuccessfully:"Password reset successfully",
      verifyEmailBeforeLogin:"Verify Email Before Login.",
      emailVerified:"Your profile updated successfully. You can now log in through the app. "
    };
  }
};
