import React, { useState, useEffect, useRef } from "react";
import { IonPage, IonHeader, IonContent, IonToolbar, IonModal, IonCol, IonInput, IonGrid, IonRow, IonAlert } from "@ionic/react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { useHistory } from "react-router-dom";
import DesktopHeader from "../../components/DesktopHeader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { getLangLabel } from "../../utility/languageTranslator";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import closeIcon from "../../images/close.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";
import parsePhoneNumberFromString from 'libphonenumber-js'
import { open } from "ionicons/icons";




const EditProfile = () => {
  const history = useHistory();
  const [profileImage, setProfileImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null); // For showing preview
  const [langCode, setlangCode] = useState("en");
  const [error, setError] = useState("");
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    gender: "",
    dob: null,
    profileUrl: "",
    phoneVerified: false,
    emailverified: false // Added to handle profile image URL
  });
  const [otpMode, setOtpMode] = useState(false);
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    HTTP_CLIENT(APIFY(`/users/${userDetails.id}`), "GET",{}, { "Authorization": `Bearer ${userDetails.token}` })
          .then((res) => {
            if (res?.data) {
              const newDetails = res?.data;
              newDetails.lastLogin = new Date();
              localStorage.setItem(
                "userDetails",
                JSON.stringify({ ...user, ...newDetails })
              );
            }
            const userDetails = JSON.parse(localStorage.getItem("userDetails"));
            setUser(userDetails);
            setPhoneVerified(userDetails?.phoneVerified || false);
            setEmailVerified(userDetails?.emailverified || false);  
            setEmailVerified(userDetails?.emailverified || false);
          })
          .catch((err) => {
            console.error("Update failed:", err);
          });
    setUser(userDetails);
    setPreviewUrl(userDetails?.profileUrl || "./assets/icon/account.png"); // Set initial preview
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
  }, [history]);

  const handleChange = (e) => {
    setError("");
    if (e instanceof Date) {
      setUser((prevUser) => ({
        ...prevUser,
        dob: e,
      }));
    } else {
      const { name, value } = e.target;
      if (name === "name") {
        const alphabetRegex = /^[a-zA-Z\s]*$/;
        if (!alphabetRegex.test(value)) {
          return;
        }
      }

      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image.");
        return;
      }

      setProfileImage(file);
      setError("");
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview); // Set preview for selected image
    }
  };

  const handleUpdate = async () => {
    const { name, email, phone, gender, dob } = user;

    if (!name || !email || !phone || !gender || !dob) {
      setError(getLangLabel(langCode)?.requireFields);
      return false;
    }

    if (!user.id) return;
    let profileUrl = null;
    const updatedUser = { ...user };
    if (profileImage) {
      console.log("new file uploaded");
      const formData = new FormData();
      formData.append("upload", profileImage);
      try {
        const response = await HTTP_CLIENT(
          APIFY("/upload"),
          "POST",
          formData,
          {},
          "formdata"
        );

        if (response && response.uploaded) {
          if (response.data && typeof response.data === "object") {
            profileUrl = `${response.data.path}${response.data.filename}`;
            updatedUser.profileUrl = profileUrl;
            updateUserDetails(updatedUser);
            setError("");
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        } else {
          console.error("Upload failed:", response.message);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      updateUserDetails(updatedUser);
    }
    // No image update, just user details
  };

  const updateUserDetails = (updatedUser) => {
    console.log(phoneVerified, "updatedUser", updatedUser);
    HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", updatedUser)
      .then((res) => {
        if (res?.data) {
          const newDetails = res?.data;
          newDetails.lastLogin = new Date();
          localStorage.setItem(
            "userDetails",
            JSON.stringify({ ...user, ...newDetails })
          );
          alert(getLangLabel(langCode)?.profileUpdated);
        }
      })
      .catch((err) => {
        console.error("Update failed:", err);
      });
  };

  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [openOtpModal,setOpenOtpModal] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const phoneUtil = PhoneNumberUtil.getInstance();

  const auth = getAuth();

  const formatPhoneNumber = (phoneNumber, countryCode) => {
    try {
      // Parse the phone number with the country code
      const number = phoneUtil.parse(phoneNumber, countryCode);

      // Format the number in the international format
      return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
    } catch (error) {
      console.error("Error parsing phone number:", error);
      return null;
    }
  };

  const setupRecaptcha = async (value) => {
    window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
      'size': 'normal',
      'callback': (response) => {
        console.log('ReCAPTCHA response:', response);
      },
    }, auth);

    const appVerifier = await window.recaptchaVerifier;
    console.log("appVerifier", appVerifier);

    try {
      const confirmationResult = await signInWithPhoneNumber(auth, user?.phone, appVerifier);
      console.log(confirmationResult)
        setConfirmationResult(confirmationResult);
        window.confirmationResult = confirmationResult;
        setOpenOtpModal(true);
        console.log(openOtpModal)
        alert("OTP sent");
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Failed to send OTP. Please try again.");
    }
  };

  useEffect(() => {
    console.log(openOtpModal)
  },[openOtpModal])
  const sendOtp = async () => {
        console.log(user.phone)
      const phoneRegex = /^\+\d{12}$/;
      if (!phoneRegex.test(user.phone) || user.phone.length != 13) {
        alert("Invalid phone number. Please enter a valid phone number.");
        return ;
      }
      else
    await setupRecaptcha(phoneNumber);
  };

  const verifyOtp = async () => {
    const otpValue = otpInputs.current.map(input => input.value).join('');
    console.log("OTP Value: ", otpValue);

    if (otpValue.length !== 6) {
      alert("Please enter a complete OTP.");
      return;
    }

    if (!confirmationResult) { // Reference confirmationResult from state
      console.error("Error: confirmationResult is not initialized.");
      alert("Please request an OTP first.");
      return;
    }

    try {
      const userCredential = await confirmationResult.confirm(otpValue);
      console.log("User signed in:", userCredential.user);
      // IonAlert("Phone number verified!");
      setPhoneVerified(true);
      const updatedUser = { ...user };
      updatedUser.phoneVerified = true;
      console.log(updatedUser)
      updateUserDetails(updatedUser);
    } catch (error) {
      console.error("Error verifying OTP:", error);
      // IonAlert("Invalid OTP. Please try again.");
    }
    finally {
      setOtpMode(false);
    }
  };

  const [countryCode, setCountryCode] = useState('');
  const handleOtp = async (e) => {
      const phoneNumber = parsePhoneNumberFromString(user.phone);
      if (phoneNumber) {
        const countryCode1 = phoneNumber.country;
        setCountryCode(countryCode1);

      }

      const number = formatPhoneNumber(user.phone, countryCode);
      setPhoneNumber(number);
      await sendOtp();
   
      setOtpMode(true);
  }
  const handleOtpModeClose = () => {
    setOtpMode(false);
  }

  const otpInputs = useRef([]);

  const handleOtpInput = (e, index) => {
    const value = e.target.value;
    if (value.length === 1 && !isNaN(value)) {
      if (otpInputs.current[index + 1]) {
        otpInputs.current[index + 1].focus();
      }
    }
  };

  const handleChangePhone = (e, country) => {
    console.log(e, country)
    setUser((prevUser) => ({
      ...prevUser,
      ["phone"]: e,
    }));
  };

  const verifyEmail = async () => {
    try{
      HTTP_CLIENT(APIFY("/users/email-verification-link"), "POST", {
        email: user.email
      }).then((res) => {
        console.log(res)
        alert(getLangLabel(langCode)?.emailVerificationLink);
        history.goBack();
      })
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <IonPage>
      <IonHeader style={{ "--background": "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav" style={{ background: "#fff" }}>
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon onClick={() => history.goBack()} />
              <img
                onClick={() => history.push("/settings")}
                alt="Settings"
                src={"./assets/icon/settings.png"}
                height={25}
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent>
        <div className="container">
          <h2 style={{ paddingLeft: "25px" }}>
            {getLangLabel(langCode)?.editProfile}
          </h2>
          <div className="coverArea">
            <div
              className="profileDetails"
              style={{ justifyContent: "center" }}
            >
              <div className="profile-pic-container">
                <input
                  type="file"
                  id="profileImage"
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <img
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  alt="Profile"
                  src={previewUrl || "./assets/icon/account.png"}
                  onClick={() =>
                    document.getElementById("profileImage").click()
                  }
                />
                <button
                  type="button"
                  className="edit-button"
                  onClick={() =>
                    document.getElementById("profileImage").click()
                  }
                >
                  <img alt="Edit" src={"./assets/icon/pencil.png"} />
                </button>
              </div>
            </div>
          </div>
          <div className="profile-additional-options">
            <ul>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.name}</label>
                  <input
                    type="text"
                    placeholder="Your name"
                    name="name"
                    value={user?.name || ""}
                    onChange={handleChange}
                  />
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.email}</label>
                  <div className="phoneCode">
                    <input
                      type="email"
                      placeholder="Your email address"
                      name="email"
                      value={user?.email || ""}
                      onChange={handleChange}
                    />
                    {/* <div className="verify">
                      <button className="verifyEmail" onClick={verifyEmail} disabled={emailVerified}>
                        {getLangLabel(langCode)?.verify}
                      </button>
                    </div> */}
                  </div>
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.phoneNumber}</label>
                  <div className="phoneCode">
                    {/* <input
                      type="tel"
                      placeholder="Your phone number"
                      name="phone"
                      value={user?.phone || ""}
                      onChange={handleChange}
                    /> */}
                    <PhoneInput
                      international
                      countryCallingCodeEditable={false}
                      defaultCountry="IN"
                      placeholder={getLangLabel(langCode)?.yourPN}
                      value={user?.phone || ""}
                      onChange={handleChangePhone}
                      style={{ width: "120%" }}
                    />
                    {/* <div className="verify">
                      <button id="sign-in-button" onClick={handleOtp} disabled={phoneVerified} >
                        {getLangLabel(langCode)?.verify}
                      </button>
                    </div> */}
                  </div>
                  {phoneError && (
                <p className="error-message" style={{ color: "red" }}>
                  {phoneError}
                </p>
              )}
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.gender}</label>
                  <div className="genderRadioBtn">
                    <label htmlFor="male">
                      <input
                        type="radio"
                        name="gender"
                        value="male"
                        id="male"
                        onChange={handleChange}
                        checked={user?.gender === "male"}
                      />
                      <span>{getLangLabel(langCode)?.male}</span>
                    </label>
                    <label htmlFor="female">
                      <input
                        type="radio"
                        name="gender"
                        value="female"
                        id="female"
                        onChange={handleChange}
                        checked={user?.gender === "female"}
                      />
                      <span>{getLangLabel(langCode)?.female}</span>
                    </label>
                    <label htmlFor="others">
                      <input
                        type="radio"
                        name="gender"
                        value="others"
                        id="others"
                        onChange={handleChange}
                        checked={user?.gender === "others"}
                      />
                      <span>{getLangLabel(langCode)?.others}</span>
                    </label>
                  </div>
                </div>
              </li>
              <li
                style={{ padding: "12px 0px", display: "block", border: "0" }}
              >
                <div className="fields">
                  <label>{getLangLabel(langCode)?.dob}</label>
                  <DatePicker
                    name="dob"
                    selected={new Date(user.dob)}
                    onChange={(date) => handleChange(date)}
                    dateFormat="d MMMM, yyyy"
                    maxDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select your birth date"
                    withPortal
                  />
                </div>
              </li>
              {error && (
                <p className="error-message" style={{ color: "red" }}>
                  {error}
                </p>
              )}
            </ul>
          </div>

          <div className="submitBtn">
            <button onClick={handleUpdate}>
              {getLangLabel(langCode)?.update}
            </button>
          </div>
        </div>
      </IonContent>
      {openOtpModal &&<IonModal
        isOpen={otpMode}
        onDidDismiss={handleOtpModeClose}
        className="otpModal"
      >
        <IonContent className="safeArea">
          <IonGrid id="input1" className="ion-text-center" style={{ margin: "10px" }}>
            <div
              className="otpCross"
              onClick={handleOtpModeClose}
              style={{ display: "flex", marginBottom: "20px" }}
            >
              <img alt="" src={closeIcon} width={20} />
            </div>
                <h4 style={{ marginTop: "-15px" }}>Enter Your OTP</h4>
                <IonRow id="input1gridrow">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <IonCol key={index} size="2">
                      <input
                        ref={(el) => (otpInputs.current[index] = el)} // Assign ref to each input
                        id={`input1otp${index + 1}`}
                        maxLength="1"
                        type="number"
                        className="input1otp"
                        onInput={(e) => handleOtpInput(e, index)} // Handle input
                      />
                    </IonCol>
                  ))}
                </IonRow>
            <div className="submitBtn">
              <button onClick={verifyOtp} >
                {getLangLabel(langCode)?.verify}
              </button>
            </div> 
          </IonGrid>
        </IonContent>
      </IonModal>}

    </IonPage>
  );
};

export default EditProfile;
