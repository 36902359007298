import React, { useState, useEffect } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import "react-multi-carousel/lib/styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import { getCityInLang, getLangState } from "../../utility/languageTranslator";
import FooterMenu from "../../components/FooterMenu";

const ViewSpecialEvents = () => {
  const history = useHistory();
  const { _id } = useParams();
  const [eventdata, setEventDetails] = useState({});
  const [leftBanner, setLeftBanner] = useState("");
  const [labelData, setLabelData] = useState("");
  const [langCode, setLangCode] = useState("en");

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Special Events: ${eventdata?.label}`,
        screenClass: "Special Events",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Special Events: ${eventdata?.label}`,
          screen_class: "Special Events",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (eventdata?.label) setAnalyticsScreen();
  }, [eventdata?.label]);
  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setLangCode(languageCode || "en");
  }, []);
  useEffect(() => {
    setLabelData(
      eventdata?.label_lang?.[langCode]
        ? eventdata?.label_lang?.[langCode]
        : eventdata?.label
    );
  }, [langCode, eventdata]);

  const getEventDetails = async () => {
    try {
      const res = await HTTP_CLIENT(APIFY(`/special-events/${_id}`), "GET");
      setLeftBanner(res?.data?.icon);
      setEventDetails(res?.data);
    } catch (err) {
      console.log("error", err);
    }
  };
  useIonViewWillEnter(() => {
    getEventDetails();
  }, [_id]);
  useEffect(() => {
    getEventDetails();
  }, [_id]);

  useIonViewDidLeave(() => {
    setEventDetails({});
    setLeftBanner("");
    setLabelData("");
  });
  return (
    <IonPage>
      <IonHeader style={{ "--background": "#fff" }}>
        <IonToolbar className="t-bar">
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon
                className=""
                onClick={() => history.goBack()}
              />
              <h4 className="title-txt">{labelData ? labelData : ""}</h4>
              <ArrowBackIosNewRoundedIcon className="visibilityHidden" />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent className="backgroundGraphics">
        <div className="columnContainer">
          <div className="bannerLeft">
            {leftBanner && (
              <img src={leftBanner ? leftBanner : ""} alt="Banner" />
            )}
            {labelData?.length > 0 && (
              <div className="labelDiv">{labelData ? labelData : ""}</div>
            )}
          </div>
          <div className="bg-grey">
            <div className="img-card">
              <div className="padding-box">
                <div className="container">
                  <div className="gridCard">
                    {eventdata?.places?.map((item, index) => (
                      <div key={index} className="cardItem">
                        <div
                          className="caros-card"
                          style={{
                            paddingBottom: "10px",
                            marginBottom: "10px",
                          }}
                          onClick={() => {
                            item.eventLabel = labelData || "";
                            localStorage.setItem(
                              "placeDetails",
                              JSON.stringify(item)
                            );
                            history.push(`/specialeventdetails/${item?._id}`);
                          }}
                        >
                          <img alt=" " src={item?.img?.[0]} />
                          <h5>
                            {item?.name_lang?.[langCode]
                              ? item?.name_lang?.[langCode]
                              : item?.name}
                          </h5>
                          <div>
                            <h6
                              style={{
                                color: "black",
                                textAlign: "left",
                                padding: "0 14px",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            >
                              <LocationOnIcon />{" "}
                              {langCode === "en"
                                ? `${item.city}, ${item.state}`
                                : `${getCityInLang(item.city, langCode)}, ${
                                    getLangState()?.[item.state]
                                  }`}
                            </h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <FooterMenu history={history} langCode={langCode} />
    </IonPage>
  );
};

export default ViewSpecialEvents;
