import axios from "axios";
import { ERRORS } from "../helpers/constants";


const VITE_API_ENDPOINT = "https://chalobharatdekhen.com/tapi"
// const VITE_API_ENDPOINT = "http://localhost:3002/api/v1"
// const VITE_API_ENDPOINT = "https://192.168.1.8:3002/api/v1"

export function APIFY(path) {
  return VITE_API_ENDPOINT.concat(path);
}


export async function HTTP_CLIENT(url, method, data, params = {}, header = "json") {
  const handleJWTExpirationError = (err) => {
    if (err?.response?.data?.error === ERRORS.TOKEN_EXPIRED) {
      document.location.href = "/"
    }
  }

  return new Promise((resolve, reject) => {
    const accessToken = localStorage.getItem('access_token');

    const headers = {};

    if (header !== 'json') {
      headers['Content-Type'] = 'multipart/form-data';
    } else {
      headers['Content-Type'] = 'application/json';
    }


    if (accessToken) {
      headers['Authorization'] = `Bearer ${accessToken}`;
    }

    params = new URLSearchParams(params).toString();
    url = url.includes('?') ? `${url}&${params}` : `${url}?${params}`;

    const options = {
      method: method,
      url: url,
      headers: headers,
      data: data
    };
    axios.request(options).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      handleJWTExpirationError(error);
      reject(error);
    });
  })
}


export async function registerUser(email, password, name, phone) {


  var user = null;

  let params = {
    "name": name,
    "email": email,
    "password": password,
    "phone": phone,
    "role": ["user"]
  }

  try {

    HTTP_CLIENT(APIFY("/users/register"), "POST", params)
      .then((data) => {
        user = data.data;

      })
      .catch((err) => {      });

    if (user) return true;

  } catch (error) {    const errorMessage = error.message;    return false;
  }
}