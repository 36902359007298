/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router";
import { IonContent, IonModal } from "@ionic/react";
import { getLangLabel } from "../utility/languageTranslator";

import { APIFY, HTTP_CLIENT } from "../services/Api";
import trash from "../images/can.png";
import closeIcon from "../images/close.png";
import eyeOpen from "../images/eye-open.png";
import eyeClose from "../images/eye-close.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";


const Account = ({ history }) => {
    const [langCode, setlangCode] = useState("en");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [activeTab, setActiveTab] = useState("login");
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [passView, setPassView] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loginUser, setLoginUser] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [userUpdated, setUserUpdated] = useState(false);
    const [user, setUser] = useState({
        name: "",
        phone: "",
        email: "",
        password: "",
        gender: "",
        dob: null,
    });

    const handleLoginSignupModeClose = () => {
        setIsLoginOpen(false);
        setUser({
            name: "",
            phone: "",
            email: "",
            password: "",
            gender: "",
            dob: null,
        });
        setActiveTab("login");
        setEmail("");
        setPassword("");
        setPassView(false);
        setPasswordError("");
        setEmailError("");
        setError("");
        setSuccess("");
    };
    const handleChange = (e) => {
        if (e instanceof Date) {
            setUser((prevUser) => ({
                ...prevUser,
                dob: e,
            }));
        } else {
            const { name, value } = e.target;
            if (name === "name") {
                const alphabetRegex = /^[a-zA-Z\s]*$/;
                if (!alphabetRegex.test(value)) {
                    return;
                }
            }

            if (name === "phone") {
                const phoneRegex = /^[0-9]*$/;
                if (!phoneRegex.test(value) || value.length > 12) {
                    return;
                }
            }
            setUser((prevUser) => ({
                ...prevUser,
                [name]: value,
            }));
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith("image/")) {
                setError("Please upload a valid image.");
                return;
            }

            setProfileImage(file);
            setError("");
            const preview = URL.createObjectURL(file);
            setPreviewUrl(preview);
        }
    };

    const handleDeleteImage = () => {
        setProfileImage(null);
        setPreviewUrl(null);
        setError("");
    };

    const account = () => {
        const loginUser = JSON.parse(localStorage.getItem("userDetails"));
        if (loginUser) {
            history.push(`/profile`);
            setIsLoginOpen(false);
        } else {
            setIsLoginOpen(true);
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleLogin = () => {
        let valid = true;

        setEmailError("");
        setPasswordError("");

        if (!email) {
            setEmailError(getLangLabel(langCode)?.emailRequired);
            valid = false;
        } else if (!validateEmail(email)) {
            setEmailError(getLangLabel(langCode)?.validEmail);
            valid = false;
        }

        if (!password) {
            setPasswordError(getLangLabel(langCode)?.passRequired);
            valid = false;
        } else if (password.length < 6) {
            setPasswordError(getLangLabel(langCode)?.passMust);
            valid = false;
        }

        if (valid) {
            HTTP_CLIENT(APIFY("/users/login"), "POST", {
                email: email,
                password: password,
            })
                .then((res) => {
                    console.log("res", res.data);
                    const user = res.data;
                    if(!user?.emailverified) {
                        alert(getLangLabel(langCode)?.verifyEmailBeforeLogin);
                    }else{
                    if (res && user) {
                        localStorage.setItem("IsLogged", true);
                        localStorage.setItem("userEmail", email);
                        localStorage.setItem("access_token", user.token);
                        user.lastLogin = new Date();
                        setLoginUser(null);
                        localStorage.setItem("userDetails", JSON.stringify(user));
                        setUserUpdated((preState) => !preState);
                        setLongitude(user || null);
                        setIsLoginOpen(false);
                    } else {
                        alert(res.message);
                    }}
                })
                .catch((err) => {
                    alert(err.response.data.message);
                });
        }
    };

    const auth = getAuth();
    async function register(profileUrl) {
        user.profileUrl = profileUrl || null;
        console.log(user)
        HTTP_CLIENT(APIFY("/users/register"), "POST", user)
            .then(async (data) => {
                // await createUserWithEmailAndPassword(auth, user?.email, user?.password);
                verifyEmail();
                setSuccess("");
                localStorage.removeItem("userDetails");
                setActiveTab("login");
                setProfileImage(null);
                setPreviewUrl("");
                history.push("/");
                setUser({
                    name: "",
                    phone: "",
                    email: "",
                    password: "",
                    gender: "",
                    dob: null,
                });
            })
            .catch(({ response }) => {
                console.log(response);
                setError(response?.data?.message);
            });
    }
    const validateSignUpForm = () => {
        const { name, email, phone, gender, dob, password } = user;

        if (!email) {
            setError(getLangLabel(langCode)?.emailRequired);
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            setError(getLangLabel(langCode)?.invalidEmail);
            return false;
        }
        // if (phone.length !== 13 || !/^\+\d{12}$/.test(phone)) {
        //     setError(getLangLabel(langCode)?.invalidPN);
        //     return false;
        // }
        // if (password.length < 6) {
        //     setError(getLangLabel(langCode)?.invalidPN2);
        //     return false;
        // }
        setError("");
        return true;
    };
    const signupHandler = async () => {
        if (!validateSignUpForm()) return null;
        let profileUrl = null;
        if (profileImage) {
            const formdata = new FormData();
            formdata.append("upload", profileImage);

            try {
                console.log(formdata, "uploadedimages---");
                const response = await HTTP_CLIENT(
                    APIFY("/upload"),
                    "POST",
                    formdata,
                    {},
                    "formdata"
                );

                if (response && response.uploaded) {
                    if (response.data && typeof response.data === "object") {
                        profileUrl = `${response.data.path}${response.data.filename}`;
                    } else {
                        console.error("Unexpected response data format:", response.data);
                    }
                } else {
                    console.error("Upload failed:", response.message);
                }
            } catch (err) {
                console.log(err);
            }
        }
        register(profileUrl);
    };

    const togglePassView = () => {
        setPassView(!passView);
    };

    const closeLoginModal = () => {
        setIsLoginOpen(false);
    };

    var target = "_system";
    var iaboptions = {
        location: "no", //Or 'no'
        hideurlbar: "yes", //Or 'no'
        hidenavigationbuttons: "yes",
        fullscreen: "yes",
        toolbarcolor: "#000000",
        closebuttoncaption: "exit",
        clearcache: "yes",
    };

    const handleChangePhone = (e) => {
        setUser((prevUser) => ({
            ...prevUser,
            ["phone"]: e,
        }));
    };

    const forgotPassword = async () => {
        try {
            console.log(email, password);
            if (!email || !validateEmail(email)) {
                alert(getLangLabel(langCode)?.emailRequired);
            }
            else {
                await HTTP_CLIENT(APIFY("/users/reset-password-link"), "POST", {
                    email: email,
                    password: password,
                })
                    .then((res) => {
                        console.log("res", res);
                        alert(getLangLabel(langCode)?.passwordReset);
                    })
                    .catch((err) => {
                        alert(err.response.data.message);
                    });
            }
        } catch (error) {
            console.log(error)
            alert(getLangLabel(langCode)?.userNotFound);
        }
    }

    useEffect(() => {
        const userObj = JSON.parse(localStorage.getItem("userDetails"));
        if (userObj) setLoginUser(userObj);
        else setLoginUser(null);
    }, [userUpdated]);

    useEffect(() => {
        const languageCode = localStorage.getItem("languageCode");
        if (languageCode) {
            setlangCode(languageCode || "en");
        } else {
            localStorage.setItem("languageCode", "en");
        }
    }, []);

    const verifyEmail = async () => {
        console.log(user)
          try{
            HTTP_CLIENT(APIFY("/users/email-verification-link"), "POST", {
              email: user.email
            }).then((res) => {
              console.log(res)
              alert(getLangLabel(langCode)?.emailVerificationLink);
            })
          } catch (error) {
            console.error(error);
          }
        }
    return (
        <>
            <img alt=" " src={"./assets/icon/account.png"} height={25} onClick={account} />

            <IonModal
                isOpen={isLoginOpen}
                onDidDismiss={handleLoginSignupModeClose}
            >
                <IonContent className="safeArea">
                    <div className="loginContainer">
                        <div
                            className=""
                            onClick={closeLoginModal}
                            style={{ display: "inline-block", marginBottom: "20px" }}
                        >
                            <img alt="" src={closeIcon} width={20} />
                        </div>
                        <div className="tabContainer">
                            <div
                                className={`tabName ${activeTab == "login" ? "active" : ""
                                    }`}
                                onClick={() => handleTabClick("login")}
                            >
                                {getLangLabel(langCode)?.logIn}
                            </div>
                            <div
                                className={`tabName ${activeTab == "signup" ? "active" : ""
                                    }`}
                                onClick={() => handleTabClick("signup")}
                            >
                                {getLangLabel(langCode)?.signUp}
                            </div>
                        </div>
                        <div className="tabContent">
                            {activeTab === "login" ? (
                                <>
                                    <h2>{getLangLabel(langCode)?.youAreBack}</h2>
                                    <p>{getLangLabel(langCode)?.loginText}</p>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.email}</label>
                                        <input
                                            type="email"
                                            placeholder={getLangLabel(langCode)?.yourEmailP}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                        {emailError && (
                                            <p className="error-message">{emailError}</p>
                                        )}
                                    </div>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.password}</label>
                                        <div className="password-container">
                                            <input
                                                type={passView ? "text" : "password"}
                                                placeholder={
                                                    getLangLabel(langCode)?.yourPasswordP
                                                }
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            <span onClick={togglePassView}>
                                                <img
                                                    alt=""
                                                    src={passView ? eyeClose : eyeOpen}
                                                    width={20}
                                                />
                                            </span>
                                            {passwordError && (
                                                <p className="error-message">{passwordError}</p>
                                            )}
                                        </div>
                                        <div className="forgotPassword">
                                            <label className="forgotPasswordText" onClick={forgotPassword}>{getLangLabel(langCode)?.forgotPassword}</label>
                                        </div>
                                    </div>
                                    {/* <div className="forgotPass">Forgot Password?</div> */}

                                    <div className="submitBtn" onClick={handleLogin}>
                                        <button> {getLangLabel(langCode)?.logIn}</button>
                                    </div>
                                    <div className="info">
                                        <p>
                                            {getLangLabel(langCode)?.loginP1}{" "}
                                            <span
                                                onClick={async () => {
                                                    let url =
                                                        "https://chalobharatdekhen.com/terms-and-conditions";
                                                    InAppBrowser.create(url, target, iaboptions);
                                                }}
                                            >
                                                {getLangLabel(langCode)?.loginP2}
                                            </span>
                                            {getLangLabel(langCode)?.loginP3}
                                            <span
                                                onClick={async () => {
                                                    let url =
                                                        "https://chalobharatdekhen.com/privacy-policy";
                                                    InAppBrowser.create(url, target, iaboptions);
                                                }}
                                            >
                                                {getLangLabel(langCode)?.loginP4}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="info">
                                        <p>
                                            {getLangLabel(langCode)?.loginP5}{" "}
                                            <strong onClick={() => handleTabClick("signup")}>
                                                {getLangLabel(langCode)?.signUp}
                                            </strong>
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p>{getLangLabel(langCode)?.signUpText}</p>

                                    <div className="profile-pic-wrapper">
                                        <label
                                            htmlFor="profileImage"
                                            className="profile-pic-label"
                                        >
                                            {previewUrl ? (
                                                <img
                                                    src={previewUrl}
                                                    alt="Profile Preview"
                                                    className="profile-pic-preview"
                                                />
                                            ) : (
                                                <div className="upload-placeholder">
                                                    <span>{getLangLabel(langCode)?.upload}</span>
                                                </div>
                                            )}
                                        </label>
                                        {previewUrl && (
                                            <button
                                                type="button"
                                                className="delete-button"
                                                onClick={handleDeleteImage}
                                            >
                                                <img src={trash} alt="" />
                                            </button>
                                        )}
                                        <input
                                            type="file"
                                            id="profileImage"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.name}</label>
                                        <input
                                            type="text"
                                            placeholder={getLangLabel(langCode)?.yourName}
                                            name="name"
                                            value={user?.name || ""}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.email}</label>
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder={getLangLabel(langCode)?.yourEmailP}
                                            value={user?.email || ""}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.phoneNumber}</label>
                                        <div className="phoneCode">
                                            <PhoneInput
                                                international
                                                countryCallingCodeEditable={false}
                                                defaultCountry="IN"
                                                placeholder={getLangLabel(langCode)?.yourPN}
                                                value={user?.phone || ""}
                                                onChange={handleChangePhone}
                                                style={{ width: "120%" }}
                                            />
                                            {/* <input
                              name="phone"
                              type="tel"
                              placeholder={getLangLabel(langCode)?.yourPN}
                              value={user?.phone || ""}
                              onChange={handleChange}
                            /> */}
                                        </div>
                                    </div>
                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.gender}</label>
                                        <div className="genderRadioBtn">
                                            <label htmlFor="male">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value="male"
                                                    id="male"
                                                    defaultValue={user?.gender || ""}
                                                    onChange={handleChange}
                                                />
                                                <span>{getLangLabel(langCode)?.male}</span>
                                            </label>
                                            <label htmlFor="female">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value="female"
                                                    id="female"
                                                    defaultValue={user?.gender || ""}
                                                    onChange={handleChange}
                                                />
                                                <span>{getLangLabel(langCode)?.female}</span>
                                            </label>
                                            <label htmlFor="others">
                                                <input
                                                    type="radio"
                                                    name="gender"
                                                    value="others"
                                                    id="others"
                                                    defaultValue={user?.gender || ""}
                                                    onChange={handleChange}
                                                />
                                                <span>{getLangLabel(langCode)?.others}</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.dob}</label>
                                        <DatePicker
                                            name="dob"
                                            selected={user?.dob ? new Date(user.dob) : null}
                                            onChange={(date) => handleChange(date)}
                                            dateFormat="d MMMM, yyyy"
                                            maxDate={new Date()}
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            placeholderText={getLangLabel(langCode)?.selectDOB}
                                            withPortal
                                        />
                                    </div>

                                    <div className="fields">
                                        <label>{getLangLabel(langCode)?.password}</label>
                                        <div className="password-container">
                                            <input
                                                name="password"
                                                type={passView ? "text" : "password"}
                                                placeholder={
                                                    getLangLabel(langCode)?.yourPasswordP
                                                }
                                                value={user?.password || ""}
                                                onChange={handleChange}
                                            />
                                            <span onClick={togglePassView}>
                                                <img
                                                    alt=""
                                                    src={passView ? eyeClose : eyeOpen}
                                                    width={20}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                    {error && (
                                        <p className="error-message" style={{ color: "red" }}>
                                            {error}
                                        </p>
                                    )}
                                    {success && (
                                        <p
                                            className="success-message"
                                            style={{ color: "green" }}
                                        >
                                            {success}
                                        </p>
                                    )}
                                    <div className="submitBtn">
                                        <button onClick={signupHandler}>
                                            {" "}
                                            {getLangLabel(langCode)?.signUp}
                                        </button>
                                    </div>
                                    <div className="info">
                                        <p>
                                            {getLangLabel(langCode)?.loginP1}{" "}
                                            <span
                                                onClick={async () => {
                                                    let url =
                                                        "https://chalobharatdekhen.com/terms-and-conditions";
                                                    InAppBrowser.create(url, target, iaboptions);
                                                }}
                                            >
                                                {getLangLabel(langCode)?.loginP2}
                                            </span>
                                            {getLangLabel(langCode)?.loginP3}
                                            <span
                                                onClick={async () => {
                                                    let url =
                                                        "https://chalobharatdekhen.com/privacy-policy";
                                                    InAppBrowser.create(url, target, iaboptions);
                                                }}
                                            >
                                                {getLangLabel(langCode)?.loginP4}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="info">
                                        <p>
                                            {getLangLabel(langCode)?.loginP5}{" "}
                                            <strong onClick={() => handleTabClick("login")}>
                                                {getLangLabel(langCode)?.logIn}
                                            </strong>
                                        </p>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </IonContent>
            </IonModal>
        </>
    );
};

export default withRouter(Account);
