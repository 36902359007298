import { useState } from "react";
import { getLangLabel } from "../../utility/languageTranslator";
import eyeOpen from "../../images/eye-open.png";
import eyeClose from "../../images/eye-close.png";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { useParams, withRouter } from "react-router";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { IonAlert } from "@ionic/react";

const VerifyLogin = () => {
    const { token } = useParams();
    const [tokenString, setTokenString] = useState(token);
    const [langCode, setlangCode] = useState("en");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passView, setPassView] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [updatedUser, setUpdatedUser] = useState({});
    const [emailVerification, setEmailVerification] = useState(false);
    var target = "_system";
    var iaboptions = {
        location: "no", //Or 'no'
        hideurlbar: "yes", //Or 'no'
        hidenavigationbuttons: "yes",
        fullscreen: "yes",
        toolbarcolor: "#000000",
        closebuttoncaption: "exit",
        clearcache: "yes",
    };

    const validateEmail = (email) => {
        if (!email) {
            setEmailError(getLangLabel(langCode)?.emailRequired);
            return false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        if (!password) {
            setPasswordError(getLangLabel(langCode)?.passRequired);
            return false;
        } else if (password.length < 6) {
            setPasswordError(getLangLabel(langCode)?.passMust);
            return false;
        }
        return true;
    }

    const handleLogin = async () => {
        setEmailError("");
        setPasswordError("");
        try {
            HTTP_CLIENT(APIFY("/users/validate-token"), "POST", {
                token: tokenString,
            }).then((res) => {
                console.log(res)
                const currentTimestamp = Math.floor(Date.now() / 1000);
                console.log(currentTimestamp, currentTimestamp > res.exp)
                if (currentTimestamp > res.exp) {
                    throw new Error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            })

            await validateEmail(email);
            await validatePassword(password);

            if (!emailError && !passwordError) {

                await HTTP_CLIENT(APIFY("/users/login"), "POST", {
                    email: email,
                    password: password,
                })
                    .then((res) => {
                        const user = res?.data;
                        console.log(user)
                        user.emailverified = true;
                        HTTP_CLIENT(APIFY(`/users/${user.id}`), "PUT", user)
                            .then((res) => {
                                alert(getLangLabel(langCode)?.emailVerified);
                                setEmailVerification(true);
                            }
                            )
                            .catch((err) => {
                                console.error("Update failed:", err);
                            });
                    })
                    .catch((err) => {
                        console.error("Login failed:", err);
                        alert(err.response.data.message);
                    });
            }
        } catch (error) {
            console.error("Login failed:", error);
        }
    }
    const togglePassView = () => {
        setPassView(!passView);
    };
    return <div className="tabContent">
        <div className="verifyLogin">
            <h4 className="fields">Login</h4>
            <div className="fields">
                <label>{getLangLabel(langCode)?.email}</label>
                <input
                    type="email"
                    placeholder={getLangLabel(langCode)?.yourEmailP}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                {emailError && (
                    <p className="error-message">{emailError}</p>
                )}
            </div>
            <div className="fields">
                <label>{getLangLabel(langCode)?.password}</label>
                <div className="password-container">
                    <input
                        type={passView ? "text" : "password"}
                        placeholder={
                            getLangLabel(langCode)?.yourPasswordP
                        }
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span onClick={togglePassView}>
                        <img
                            alt=""
                            src={passView ? eyeClose : eyeOpen}
                            width={20}
                        />
                    </span>
                    {passwordError && (
                        <p className="error-message">{passwordError}</p>
                    )}
                </div>
            </div>
            {/* <div className="forgotPass">Forgot Password?</div> */}

            <div className="submitBtn" onClick={handleLogin}>
                <button> {getLangLabel(langCode)?.logIn}</button>
            </div>
            <div className="info">
                <p>
                    {getLangLabel(langCode)?.loginP1}{" "}
                    <span
                        onClick={async () => {
                            let url =
                                "https://chalobharatdekhen.com/terms-and-conditions";
                            InAppBrowser.create(url, target, iaboptions);
                        }}
                    >
                        {getLangLabel(langCode)?.loginP2}
                    </span>
                    {getLangLabel(langCode)?.loginP3}
                    <span
                        onClick={async () => {
                            let url =
                                "https://chalobharatdekhen.com/privacy-policy";
                            InAppBrowser.create(url, target, iaboptions);
                        }}
                    >
                        {getLangLabel(langCode)?.loginP4}
                    </span>
                </p>
            </div>
            <IonAlert isOpen={emailVerification} onDidDismiss={() => setEmailVerification(false)}>Email Verified Re-Open App</IonAlert>
        </div>
    </div>
};

export default withRouter(VerifyLogin); 