import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonLoading,
  IonModal,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import { Navigation, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "react-multi-carousel/lib/styles.css";
import {
  getDataByCollection,
  getLatestAppVersion,
  getStateList,
} from "../../modalconfig";
import AreaSelector from "../../components/AreaSelector";
import { APIFY, HTTP_CLIENT } from "../../services/Api";

import { Network } from "@capacitor/network";
import { Geolocation } from "@capacitor/geolocation";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import { isMobile } from "react-device-detect";
import { SpeechRecognition as CapacitorSpeechRecognition } from "@capacitor-community/speech-recognition";
import AnimatedStartScreen from "../../components/AnimatedStartScreen";
import DesktopHeader from "../../components/DesktopHeader";
import Footer from "../../components/Footer";
import maplogo from "../../images/Vector Smart Object.png";
import trash from "../../images/can.png";
import card1 from "../../images/tiger.jpg";
import card2 from "../../images/lotusTemple.jpg";
import card3 from "../../images/temp1.png";
import txtimg from "../../images/Group 3.png";
import { isPlatform } from "@ionic/react";
import remove from "../../images/remove.png";
import closeIcon from "../../images/close.png";
import eyeOpen from "../../images/eye-open.png";
import eyeClose from "../../images/eye-close.png";
import bannerBottom from "../../images/banner-bottom.png";
import indiaMapColorful from "../../images/india-map-colurful.png";
import indiaMapColorfulHi from "../../images/india-map-colurful-hi.png";
import neverBefore from "../../images/like-never-before.png";
import neverBeforeHi from "../../images/like-never-before-hi.png";
import { getCityInLang, getLangLabel } from "../../utility/languageTranslator";
import SearchInput from "../../components/SearchInput";
import { App } from "@capacitor/app";
import UpdateAvailableAlert from "../../components/UpdateAvailableAlert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import Account from "../../components/Account";

const Home = ({ history }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const location = useLocation();
  const [districtName, setDistrictName] = useState("");
  const [stateName, setStateName] = useState("");
  const [loginUser, setLoginUser] = useState(null);
  const [searchlocation, setSearchLocation] = useState(() => {
    const storedValue = localStorage.getItem("searchlocation");
    return storedValue ? storedValue === "true" : false;
  });
  const [profileImage, setProfileImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [passView, setPassView] = useState(false);
  const [activeTab, setActiveTab] = useState("login");
  const [isOpen, setIsOpen] = useState(true);
  const [netavailable, setnetavailable] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [filters, setFilters] = useState([]);
  const [postorImg, setPostorImg] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [specialEvents, setSpecialEvents] = useState([]);
  const [langCode, setlangCode] = useState("en");
  const [cities, setCities] = useState([]);
  const [netChecked, setNetChecked] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [currentVersion, setCurrentVersion] = useState(null);
  const [latestAppVersion, setLatestAppVersion] = useState(null);
  const [updateAvailable, setUpdateAvailable] = useState(false);
  const [show, setShow] = useState(false);
  const [toolbarBg, setToolbarBg] = useState("transparent");
  const [toolbarShadow, setToolbarShadow] = useState("none");
  const [toolbarHeight, setToolbarHeight] = useState("75px");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userUpdated, setUserUpdated] = useState(false);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    gender: "",
    dob: null,
  });
  const handleLoginSignupModeClose = () => {
    setIsLoginOpen(false);
    setUser({
      name: "",
      phone: "",
      email: "",
      password: "",
      gender: "",
      dob: null,
    });
    setActiveTab("login");
    setEmail("");
    setPassword("");
    setPassView(false);
    setPasswordError("");
    setEmailError("");
    setError("");
    setSuccess("");
  };
  const handleChange = (e) => {
    if (e instanceof Date) {
      setUser((prevUser) => ({
        ...prevUser,
        dob: e,
      }));
    } else {
      const { name, value } = e.target;
      if (name === "name") {
        const alphabetRegex = /^[a-zA-Z\s]*$/;
        if (!alphabetRegex.test(value)) {
          return;
        }
      }

      if (name === "phone") {
        const phoneRegex = /^[0-9]*$/;
        if (!phoneRegex.test(value) || value.length > 12) {
          return;
        }
      }
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const userObj = JSON.parse(localStorage.getItem("userDetails"));
    if (userObj) setLoginUser(userObj);
    else setLoginUser(null);
  }, [userUpdated]);

  const handleScroll = (event) => {
    const scrollTop = event.detail.scrollTop;
    const newHeight = Math.max(56, 75 - (scrollTop / 100) * (75 - 56));

    setToolbarHeight(`${newHeight}px`);
    if (scrollTop > 100) {
      setToolbarBg("#fff");
      setToolbarShadow("rgb(136 136 136 / 28%) 0px 4px 6px");
    } else {
      setToolbarBg("transparent");
      setToolbarShadow("none");
    }
  };

  const handleTabClick = (tab) => {
    console.log('hello')
    setActiveTab(tab);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith("image/")) {
        setError("Please upload a valid image.");
        return;
      }

      setProfileImage(file);
      setError("");
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
    }
  };

  const handleDeleteImage = () => {
    setProfileImage(null);
    setPreviewUrl(null);
    setError("");
  };

  useEffect(() => {
    const getStatus = async () => {
      const status = await sessionStorage.getItem("splashLogin");
      if (!status) {
        setShow(true);
        await sessionStorage.setItem("splashLogin", "true");
        const timer = setTimeout(() => {
          setIsAnimated(true);
        }, 5000);
        return () => clearTimeout(timer);
      } else {
        setShow(false);
        setIsAnimated(false);
      }
    };

    getStatus();

    // Cleanup function to reset the splash screen status when the component unmounts
    return () => {
      localStorage.setItem("splashLogin", "false");
    };
  }, [location.key]);
  function compareVersions() {
    const parseVersion = (version) => version.split(".").map(Number);

    const current = parseVersion(currentVersion);
    const latest = parseVersion(latestAppVersion);

    for (let i = 0; i < latest.length; i++) {
      if (current[i] < latest[i]) {
        return -1; // Current version is older
      } else if (current[i] > latest[i]) {
        return 1; // Current version is newer
      }
    }
    return 0; // Versions are the same
  }
  useEffect(() => {
    const fetchAppVersions = async () => {
      try {
        const latestVersionRes = await getLatestAppVersion();
        const { version } = await App.getInfo();

        console.log("testing", latestVersionRes);
        let latestVersion = null;
        if (isPlatform("android")) latestVersion = latestVersionRes.android;
        if (isPlatform("ios")) latestVersion = latestVersionRes.ios;

        latestVersion = latestVersionRes.android;
        setCurrentVersion(version);
        setLatestAppVersion(latestVersion);
      } catch (error) {
        console.error("Failed to get app version: ", error);
      }
    };
    if (isPlatform("android") || isPlatform("ios")) fetchAppVersions();
  }, []);
  useEffect(() => {
    if (currentVersion && latestAppVersion) {
      const compare = compareVersions();
      if (compare === -1) {
        setUpdateAvailable(true);
      }
    }
  }, [currentVersion, latestAppVersion]);

  const getCorrectCityName = (city) => {
    const cities = JSON.parse(localStorage.getItem("cities"));
    const res = cities?.filter(
      (obj) => obj?.city === city || obj?.cityKey === city
    );
    setCities(res[0]?.city);
    return res[0]?.city;
  };
  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth > 1024);
  };
  const apiKey = "AIzaSyA8UPOL-NXIwjEzHwfXRcVvTICWQ64AQ6Q";

  //

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Home Page`,
        screenClass: "Home Page",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Home Page`,
          screen_class: "Home Page",
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };

  useEffect(() => {
    setAnalyticsScreen();
  }, []);

  const logCurrentNetworkStatus = async () => {
    try {
      const status = await Network.getStatus();
      console.log("Network status:", status.connected);
      setnetavailable(status.connected || false);
      setNetChecked(true);
      localStorage.setItem("Networkstatus", status.connected);
    } catch (error) {
      console.error("Error checking network status:", error);
    }
  };

  useEffect(() => {
    logCurrentNetworkStatus();

    const networkStatusChangeListener = Network.addListener(
      "networkStatusChange",
      (status) => {
        console.log("Network status changed:", status.connected);
        setnetavailable(status.connected || false);
        setNetChecked(true);
        localStorage.setItem("Networkstatus", status.connected);
      }
    );

    return () => {
      networkStatusChangeListener.remove();
    };
  }, []);

  //
  useEffect(() => {
    const requestMicrophonePermissions = async () => {
      try {
        CapacitorSpeechRecognition.requestPermissions();
      } catch (error) {
        console.error("Failed to request microphone permissions:", error);
      }
    };

    if (isMobile) {
      requestMicrophonePermissions();
    }
  }, []);

  //
  const findLocation = () => {
    setSearchLocation(true);
  };

  const explorePlace = () => {
    if (stateName !== "" && districtName !== "") {
      localStorage.setItem(
        "placeDetails",
        JSON.stringify({ state: stateName, city: districtName })
      );
      history.push(`/placeinformation`);
    }
  };

  ///setting expiration time for the district value from API
  const setWithExpiration = (key, value, ttl) => {
    const now = new Date().getTime();
    const item = {
      value: value,
      expiry: now + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
    console.log("setting item", item);
  };
  ///
  ///get with expiration
  const getWithExpiration = (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    console.log("getting item parsed", item);

    const now = new Date().getTime();

    if (now > item.expiry) {
      // If the item is expired, delete the item from storage and return null
      localStorage.removeItem(key);
      return null;
    }
    console.log("getting item parsed2", item.value);
    return item.value;
  };

  useEffect(() => {
    const printCurrentPosition = async () => {
      let getdistname = await getWithExpiration("districtget");
      let getstname = await getWithExpiration("stateget");
      console.log("distName===123", getdistname);
      console.log("stateName===123", getstname);
      if (getdistname !== null && getstname !== null) {
        const cityName = getCorrectCityName(getdistname);
        setDistrictName(cityName);
        setStateName(getstname);
      }
      if (getdistname === null) {
        try {
          // Request permission to access geolocation
          if (isMobile) {
            let permission = await Geolocation.checkPermissions();
            if (permission.location === "granted") {
              // Permission granted, proceed with geolocation retrieval
              const coordinates = await Geolocation.getCurrentPosition();
              const { latitude, longitude } = coordinates.coords;
              setLatitude(latitude);
              setLongitude(longitude);
              const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
              );
              const data = await response.json();
              console.log("test1", data.plus_code.compound_code);
              if (data.status === "OK") {
                let district = "";
                let state = "";
                const addressComponents = data.results[0].address_components;

                for (const component of addressComponents) {
                  if (component.types.includes("administrative_area_level_3")) {
                    district = component.long_name;
                  }
                  if (component.types.includes("administrative_area_level_1")) {
                    state = component.long_name;
                  }
                  if (district && state) break;
                }
                const cityName = getCorrectCityName(district);
                setDistrictName(cityName);

                setStateName(state);
                setWithExpiration("districtget", district, 900000);
                setWithExpiration("stateget", state, 900000);
              } else {
                console.error("Failed to retrieve address information");
              }
            } else if (permission.location !== "granted") {
              alert(getLangLabel(langCode)?.geoPermission);
              permission = await Geolocation.requestPermissions();
              if (permission.location !== "granted") {
                return null;
              } else if (permission.location === "granted") {
                printCurrentPosition();
              }
            }
          } else {
            if (navigator.geolocation) {
              // check if permission is granted
              navigator.permissions
                .query({ name: "geolocation" })
                .then((permissionStatus) => {
                  console.log("permissionStatus", permissionStatus);
                  if (
                    permissionStatus.state === "prompt" ||
                    permissionStatus.state === "granted"
                  ) {
                    // permission already granted, get the current user's location
                    navigator.geolocation.getCurrentPosition(
                      async (position) => {
                        // save the geolocation coordinates in two variables
                        const { latitude, longitude } = position.coords;
                        setLatitude(latitude);
                        setLongitude(longitude);
                        const response = await fetch(
                          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
                        );
                        const data = await response.json();
                        if (data.status === "OK") {
                          let district = "";
                          let state = "";
                          const addressComponents =
                            data.results[0].address_components;

                          for (const component of addressComponents) {
                            if (
                              component.types.includes(
                                "administrative_area_level_3"
                              )
                            ) {
                              district = component.long_name;
                            }
                            if (
                              component.types.includes(
                                "administrative_area_level_1"
                              )
                            ) {
                              state = component.long_name;
                            }
                            if (district && state) break;
                          }
                          const cityName = getCorrectCityName(district);
                          setDistrictName(cityName);
                          window.location.reload();
                          setStateName(state);
                          setWithExpiration("districtget", district, 900000);
                          setWithExpiration("stateget", state, 900000);
                        } else {
                          console.error(
                            "Failed to retrieve address information"
                          );
                        }
                      },

                      // if there was an error getting the user's location
                      (error) => {
                        console.error("Error getting user location:", error);
                      }
                    );
                  } else if (permissionStatus.state !== "granted") {
                    alert(getLangLabel(langCode)?.geoPermission);

                    if (permissionStatus.state !== "granted") {
                      return null;
                    } else if (permissionStatus.state === "granted") {
                      printCurrentPosition();
                      setSearchLocation(true);
                    }
                  }
                });
            } else {
              console.error("Geolocation is not supported by this browser.");
            }
          }
        } catch (error) {
          console.error("Error getting current position:", error);
        } finally {
          setSearchLocation(true);
        }
      } else {
        console.log("district name already present");
      }
    };

    if (searchlocation === true && stateName == "" && districtName == "") {
      localStorage.setItem("searchlocation", searchlocation);
      printCurrentPosition();
    }
  }, [searchlocation, cities]);
  const getCities = async () => {
    const res1 = await getStateList({});
    localStorage.setItem("cities", JSON.stringify(res1?.city || []));
  };
  useEffect(() => {
    setIsOpen(true);
    getSpecialEvents();
    checkIsDesktop();
    getCities();
    window.addEventListener("resize", checkIsDesktop);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, []);

  const getSpecialEvents = async () => {
    try {
      const res = await HTTP_CLIENT(APIFY(`/special-events`), "GET");
      setSpecialEvents(res.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    sessionStorage.setItem("isOpen", false);
  };

  const account = () => {
    const loginUser = JSON.parse(localStorage.getItem("userDetails"));
    if (loginUser) {
      history.push(`/profile`);
      setIsLoginOpen(false);
    } else {
      setIsLoginOpen(true);
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    let valid = true;

    setEmailError("");
    setPasswordError("");
console.log(email)
    if (!email || !validateEmail(email)) {
      setEmailError(getLangLabel(langCode)?.emailRequired);
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError(getLangLabel(langCode)?.validEmail);
      valid = false;
    }

    if (!password) {
      setPasswordError(getLangLabel(langCode)?.passRequired);
      valid = false;
    } else if (password.length < 6) {
      setPasswordError(getLangLabel(langCode)?.passMust);
      valid = false;
    }

    if (valid) {
      HTTP_CLIENT(APIFY("/users/login"), "POST", {
        email: email,
        password: password,
      })
        .then((res) => {
          console.log("res", res.data);
          const user = res.data;
          if (res && user) {
            localStorage.setItem("IsLogged", true);
            localStorage.setItem("userEmail", email);
            localStorage.setItem("access_token", user.token);
            user.lastLogin = new Date();
            setLoginUser(null);
            localStorage.setItem("userDetails", JSON.stringify(user));
            setUserUpdated((preState) => !preState);
            setLongitude(user || null);
            setIsLoginOpen(false);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          alert(err.response.data.message);
        });
    }
  };

  const closeLoginModal = () => {
    setIsLoginOpen(false);
  };

  useEffect(() => {
    const showModal =
      sessionStorage.getItem("isOpen") === "true" ||
      sessionStorage.getItem("isOpen") === null;
    setIsOpen(showModal);
    const languageCode = localStorage.getItem("languageCode");
    if (languageCode) {
      setlangCode(languageCode || "en");
    } else {
      localStorage.setItem("languageCode", "en");
    }
  }, [location.key]);

  useEffect(() => {
    const fetchData = async () => {
      if (netavailable) {
        setShowLoading(true);
        let fil = await getDataByCollection("filters");
        localStorage.setItem("filters", JSON.stringify(fil));
        let postor_img = await getDataByCollection("homeimages");
        setFilters(fil);
        setDataLoaded(true);
        if (postor_img?.length > 0) {
          setPostorImg(postor_img[0]?.data);
        }
        setShowLoading(false);
      } else {
        setDataLoaded(true);
        setShowLoading(false);
      }
    };
    if (netChecked) fetchData();
  }, [netChecked, netavailable, langCode]);

  const auth = getAuth();
  async function register(profileUrl) {
    user.profileUrl = profileUrl || null;
    verifyEmail();
    HTTP_CLIENT(APIFY("/users/register"), "POST", user)
      .then(async (data) => {
        // await createUserWithEmailAndPassword(auth, user?.email, user?.password);
        setSuccess("");
        localStorage.removeItem("userDetails");
        setActiveTab("login");
        setProfileImage(null);
        setPreviewUrl("");
        history.push("/");
        setUser({
          name: "",
          phone: "",
          email: "",
          password: "",
          gender: "",
          dob: null,
        });
      })
      .catch(({ response }) => {
        setError(response?.data?.message);
      });
  }
  const validateSignUpForm = () => {
    const { name, email, phone, gender, dob, password } = user;

    if (!name || !email || !phone || !gender || !dob || !password) {
      setError(getLangLabel(langCode)?.requireFields);
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setError(getLangLabel(langCode)?.invalidEmail);
      return false;
    }
    if (phone.length !== 13 || !/^\+\d{12}$/.test(phone)) {
      setError(getLangLabel(langCode)?.invalidPN);
      return false;
    }
    if (password.length < 6) {
      setError(getLangLabel(langCode)?.invalidPN2);
      return false;
    }
    setError("");
    return true;
  };
  const signupHandler = async () => {
    console.log('hello')
    if (!validateSignUpForm()) return null;
    let profileUrl = null;
    if (profileImage) {
      const formdata = new FormData();
      formdata.append("upload", profileImage);

      try {
        console.log(formdata, "uploadedimages---");
        const response = await HTTP_CLIENT(
          APIFY("/upload"),
          "POST",
          formdata,
          {},
          "formdata"
        );

        if (response && response.uploaded) {
          if (response.data && typeof response.data === "object") {
            profileUrl = `${response.data.path}${response.data.filename}`;
          } else {
            console.error("Unexpected response data format:", response.data);
          }
        } else {
          console.error("Upload failed:", response.message);
        }
      } catch (err) {
        console.log(err);
      }
    }
    register(profileUrl);
  };

  const togglePassView = () => {
    setPassView(!passView);
  };

  var target = "_system";
  var iaboptions = {
    location: "no", //Or 'no'
    hideurlbar: "yes", //Or 'no'
    hidenavigationbuttons: "yes",
    fullscreen: "yes",
    toolbarcolor: "#000000",
    closebuttoncaption: "exit",
    clearcache: "yes",
  };

  const handleChangePhone = (e) => {
    setUser((prevUser) => ({
      ...prevUser,
      ["phone"]: e,
    }));
  };

  const forgotPassword= async () => {
    try {
      console.log(email, password);
      if(!email || !validateEmail(email)){ 
        alert(getLangLabel(langCode)?.emailRequired);
      }
      else{
      await HTTP_CLIENT(APIFY("/users/reset-password-link"), "POST", {
        email: email,
        password: password,
      })
        .then((res) => {
          console.log("res", res);
          alert(getLangLabel(langCode)?.passwordReset);
        })
        .catch((err) => {
          alert(err.response.data.message);
        });}
    } catch (error) {
      console.log(error)
      alert(getLangLabel(langCode)?.userNotFound);
    }
  }

   const verifyEmail = async () => {
    console.log(user)
      try{
        HTTP_CLIENT(APIFY("/users/email-verification-link"), "POST", {
          email: user.email
        }).then((res) => {
          console.log(res)
          alert(getLangLabel(langCode)?.emailVerificationLink);
        })
      } catch (error) {
        console.error(error);
      }
    }

  return (
    <IonPage>
      {show && <AnimatedStartScreen isAnimated={isAnimated} />}

      <IonLoading
        cssClass="my-custom-class"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={"Please wait..."}
      />
      <IonHeader
        translucent
        className={`ion-no-border ${isDesktop ? "translucent-header" : ""}`}
      >
        <IonToolbar
          className="transparentToolbar t-bar"
          style={{
            background: toolbarBg,
            transition: "0.3s ease",
            boxShadow: toolbarShadow,
            "--min-height": toolbarHeight,
          }}
        >
          <div className="top-nav">
            <div className="nav_inner">
              <IonButtons slot="end">
                <IonMenuButton style={{ color: "#333333" }} />
              </IonButtons>
              {/* <h4 className="title-txt">{getLangLabel(langCode)?.home}</h4> */}
              <IonButtons slot="end">
                {/* <img alt=" " src={"./assets/icon/account.png"} height={25} /> */}
                <Account />
              </IonButtons>
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent
        fullscreen
        className={isDesktop ? "overlapped" : ""}
        onIonScroll={handleScroll}
        scrollEvents={true}
      >
        {netavailable && (
          <>
            <div className="home-sec">
              <div className="bannerArea">
                <div className="indiaMapColorful">
                  {langCode === "hi" ? (
                          <img src={indiaMapColorfulHi} alt="" />

                        ) : (
                          <img src={indiaMapColorful} alt="" />
                        )}
                </div>

                <img src={bannerBottom} alt="" />
                <div className="bottomPattern"></div>
              </div>
              <div className="goldenBg">
                <SearchInput history={history} langCode={langCode} />
                <div className="">
                  {filters && (
                    <>
                      <div className="locationChoose">
                        {!searchlocation ? (
                          <div
                            className="locationChooseLabel"
                            onClick={findLocation}
                          >
                            {getLangLabel(langCode)?.location}
                          </div>
                        ) : (
                          districtName && (
                            <div
                              className="locationChooseLabel"
                              onClick={explorePlace}
                            >
                              {getLangLabel(langCode)?.locationL1}
                              {langCode === "en"
                                ? districtName
                                : getCityInLang(districtName, langCode)}
                              <p>{getLangLabel(langCode)?.locationL2}</p>
                            </div>
                          )
                        )}
                      </div>
                      <div className="neverBefore">
                        {langCode === "hi" ? (
                          <img src={neverBeforeHi} alt="" />

                        ) : (
                          <img src={neverBefore} alt="" />
                        )}
                      </div>
                      <div className="home-carousel2">
                        <div className="filterSlider">
                          <Swiper
                            modules={[Navigation, A11y]}
                            className="carousel-container"
                            navigation={isDesktop ? true : false}
                            spaceBetween={20}
                            slidesPerView={3}
                            speed={500}
                            slidesOffsetAfter={10}
                            slidesOffsetBefore={0}
                            resistanceRatio={0}
                            freeMode={true}
                            breakpoints={{
                              575: {
                                slidesPerView: 3,
                              },
                              768: {
                                slidesPerView: 5,
                              },
                              1025: {
                                slidesPerView: 6,
                              },
                              1400: {
                                slidesPerView: 7,
                              },
                            }}
                            loop={false}
                          >
                            {filters?.map((item, index) => {
                              return (
                                <SwiperSlide
                                  className="carousel-item-padding-40-px"
                                  key={index}
                                >
                                  <div
                                    className="caros-card1 dis_card1"
                                    style={{
                                      background: `url(${item.icon})`,
                                      backgroundSize: "cover",
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                    }}
                                    onClick={() => {
                                      if (item?.subFilter?.length > 0) {
                                        localStorage.setItem(
                                          "filterDetails",
                                          JSON.stringify(item || [])
                                        );
                                        history.push(`/viewDetails`);
                                      } else {
                                        let data = {
                                          filterType: "type",
                                          filtername: item.label,
                                          state: "",
                                          city: "",
                                        };
                                        localStorage.setItem(
                                          "filterDetails",
                                          JSON.stringify(data)
                                        );
                                        history.push(`/viewplacescard`);
                                      }
                                    }}
                                  >
                                    {/* <img alt="filter img" src={item.icon} /> */}
                                  </div>
                                  <h5>
                                    {item?.label_lang?.[langCode]
                                      ? item?.label_lang?.[langCode]
                                      : item?.label}
                                  </h5>
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div>
                      </div>
                    </>
                  )}
                  <div className="map-container">
                    <h5
                      style={{
                        color: "black",
                        marginBottom: "0px",
                        fontWeight: "bolder",
                        fontSize: "15px",
                        marginTop: "0px",
                        padding: "20px 5px 20px 5px",
                      }}
                    >
                      {getLangLabel(langCode)?.map}
                    </h5>
                    <AreaSelector langCode={langCode} />
                  </div>

                  <div className="our-favourites">
                    {getLangLabel(langCode)?.ourFavourites}
                  </div>
                  <div className="home-carousel3">
                    <Swiper
                      modules={[Navigation, A11y]}
                      className="carousel-container"
                      navigation={isDesktop ? true : false}
                      slidesPerView={5}
                      speed={500}
                      slidesOffsetAfter={0}
                      slidesOffsetBefore={0}
                      resistanceRatio={0}
                      freeMode={true}
                      breakpoints={{
                        575: {
                          slidesPerView: 5,
                        },
                        768: {
                          slidesPerView: 5,
                        },
                        1025: {
                          slidesPerView: 8,
                        },
                        1400: {
                          slidesPerView: 8,
                        },
                      }}
                      loop={false}
                    >
                      {specialEvents?.map((item, index) => (
                        <SwiperSlide
                          className="carousel-item-padding-40-px"
                          key={index}
                        >
                          <div
                            className="caros-card1 dis_card1"
                            onClick={() =>
                              history.push(`/viewspecialevents/${item?._id}`)
                            }
                            style={{
                              background: `url(${item.icon})`,
                              backgroundSize: "cover",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                            }}
                          ></div>
                          <div className="card-caption">
                            <h5 style={{ background: "none" }}>
                              {item?.label_lang?.[langCode]
                                ? item?.label_lang?.[langCode]
                                : item?.label}
                            </h5>
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>

            {isDesktop && (isOpen === true || isOpen === null) && (
              <IonModal isOpen={isOpen} onDidDismiss={() => setIsOpen(false)}>
                <IonContent className="pageContent">
                  <div className="AppLandingPage">
                    <div className="spacer"></div>
                    <img
                      src={maplogo}
                      className="img-fluid mapLogo"
                      alt="Map Logo"
                    />
                    <div className="remove-container" onClick={closeModal}>
                      <img alt=" " src={remove} width={40} />
                    </div>
                    <div className="spacer"></div>
                    <div className="tiles">
                      <div
                        className="logincard"
                        style={{
                          backgroundImage: `url(${card1})`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          backgroundPositionX: "-30px",
                        }}
                      ></div>
                      <div className="logincard" style={{ marginTop: "60px" }}>
                        <img alt=" " src={card3} />
                      </div>
                      <div className="logincard">
                        <img alt=" " src={card2} />
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <img
                      src={txtimg}
                      className="img-fluid textLogo"
                      alt="Text Img"
                    />
                    <div className="spacer"></div>
                  </div>
                </IonContent>
              </IonModal>
            )}

            <IonModal
              isOpen={isLoginOpen}
              onDidDismiss={handleLoginSignupModeClose}
            >
              <IonContent className="safeArea">
                <div className="loginContainer">
                  <div
                    className=""
                    onClick={closeLoginModal}
                    style={{ display: "inline-block", marginBottom: "20px" }}
                  >
                    <img alt="" src={closeIcon} width={20} />
                  </div>
                  <div className="tabContainer">
                    <div
                      className={`tabName ${activeTab == "login" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("login")}
                    >
                      {getLangLabel(langCode)?.logIn}
                    </div>
                    <div
                      className={`tabName ${activeTab == "signup" ? "active" : ""
                        }`}
                      onClick={() => handleTabClick("signup")}
                    >
                      {getLangLabel(langCode)?.signUp}
                    </div>
                  </div>
                  <div className="tabContent">
                    {activeTab === "login" ? (
                      <>
                        <h2>{getLangLabel(langCode)?.youAreBack}</h2>
                        <p>{getLangLabel(langCode)?.loginText}</p>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.email}</label>
                          <input
                            type="email"
                            placeholder={getLangLabel(langCode)?.yourEmailP}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {emailError && (
                            <p className="error-message">{emailError}</p>
                          )}
                        </div>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.password}</label>
                          <div className="password-container">
                            <input
                              type={passView ? "text" : "password"}
                              placeholder={
                                getLangLabel(langCode)?.yourPasswordP
                              }
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <span onClick={togglePassView}>
                              <img
                                alt=""
                                src={passView ? eyeClose : eyeOpen}
                                width={20}
                              />
                            </span>
                            {passwordError && (
                              <p className="error-message">{passwordError}</p>
                            )}
                          </div>
                          <div className="forgotPassword">
                            <label className="forgotPasswordText" onClick={forgotPassword}>{getLangLabel(langCode)?.forgotPassword}</label>
                          </div>
                        </div>
                        {/* <div className="forgotPass">Forgot Password?</div> */}

                        <div className="submitBtn" onClick={handleLogin}>
                          <button> {getLangLabel(langCode)?.logIn}</button>
                        </div>
                        <div className="info">
                          <p>
                            {getLangLabel(langCode)?.loginP1}{" "}
                            <span
                              onClick={async () => {
                                let url =
                                  "https://chalobharatdekhen.com/terms-and-conditions";
                                InAppBrowser.create(url, target, iaboptions);
                              }}
                            >
                              {getLangLabel(langCode)?.loginP2}
                            </span>
                            {getLangLabel(langCode)?.loginP3}
                            <span
                              onClick={async () => {
                                let url =
                                  "https://chalobharatdekhen.com/privacy-policy";
                                InAppBrowser.create(url, target, iaboptions);
                              }}
                            >
                              {getLangLabel(langCode)?.loginP4}
                            </span>
                          </p>
                        </div>
                        <div className="info">
                          <p>
                            {getLangLabel(langCode)?.loginP5}{" "}
                            <strong onClick={() => handleTabClick("signup")}>
                              {getLangLabel(langCode)?.signUp}
                            </strong>
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <p>{getLangLabel(langCode)?.signUpText}</p>

                        <div className="profile-pic-wrapper">
                          <label
                            htmlFor="profileImage"
                            className="profile-pic-label"
                          >
                            {previewUrl ? (
                              <img
                                src={previewUrl}
                                alt="Profile Preview"
                                className="profile-pic-preview"
                              />
                            ) : (
                              <div className="upload-placeholder">
                                <span>{getLangLabel(langCode)?.upload}</span>
                              </div>
                            )}
                          </label>
                          {previewUrl && (
                            <button
                              type="button"
                              className="delete-button"
                              onClick={handleDeleteImage}
                            >
                              <img src={trash} alt="" />
                            </button>
                          )}
                          <input
                            type="file"
                            id="profileImage"
                            accept="image/*"
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                        </div>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.name}</label>
                          <input
                            type="text"
                            placeholder={getLangLabel(langCode)?.yourName}
                            name="name"
                            value={user?.name || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.email}</label>
                          <input
                            name="email"
                            type="email"
                            placeholder={getLangLabel(langCode)?.yourEmailP}
                            value={user?.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.phoneNumber}</label>
                          <div className="phoneCode">
                            <PhoneInput
                              international
                              countryCallingCodeEditable={false}
                              defaultCountry="IN"
                              placeholder={getLangLabel(langCode)?.yourPN}
                              value={user?.phone || ""}
                              onChange={handleChangePhone}
                              style={{ width: "120%" }}
                            />
                            {/* <input
                              name="phone"
                              type="tel"
                              placeholder={getLangLabel(langCode)?.yourPN}
                              value={user?.phone || ""}
                              onChange={handleChange}
                            /> */}
                          </div>
                        </div>
                        <div className="fields">
                          <label>{getLangLabel(langCode)?.gender}</label>
                          <div className="genderRadioBtn">
                            <label htmlFor="male">
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                id="male"
                                defaultValue={user?.gender || ""}
                                onChange={handleChange}
                              />
                              <span>{getLangLabel(langCode)?.male}</span>
                            </label>
                            <label htmlFor="female">
                              <input
                                type="radio"
                                name="gender"
                                value="female"
                                id="female"
                                defaultValue={user?.gender || ""}
                                onChange={handleChange}
                              />
                              <span>{getLangLabel(langCode)?.female}</span>
                            </label>
                            <label htmlFor="others">
                              <input
                                type="radio"
                                name="gender"
                                value="others"
                                id="others"
                                defaultValue={user?.gender || ""}
                                onChange={handleChange}
                              />
                              <span>{getLangLabel(langCode)?.others}</span>
                            </label>
                          </div>
                        </div>

                        <div className="fields">
                          <label>{getLangLabel(langCode)?.dob}</label>
                          <DatePicker
                            name="dob"
                            selected={user?.dob ? new Date(user.dob) : null}
                            onChange={(date) => handleChange(date)}
                            dateFormat="d MMMM, yyyy"
                            maxDate={new Date()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText={getLangLabel(langCode)?.selectDOB}
                            withPortal
                          />
                        </div>

                        <div className="fields">
                          <label>{getLangLabel(langCode)?.password}</label>
                          <div className="password-container">
                            <input
                              name="password"
                              type={passView ? "text" : "password"}
                              placeholder={
                                getLangLabel(langCode)?.yourPasswordP
                              }
                              value={user?.password || ""}
                              onChange={handleChange}
                            />
                            <span onClick={togglePassView}>
                              <img
                                alt=""
                                src={passView ? eyeClose : eyeOpen}
                                width={20}
                              />
                            </span>
                          </div>
                        </div>
                        {error && (
                          <p className="error-message" style={{ color: "red" }}>
                            {error}
                          </p>
                        )}
                        {success && (
                          <p
                            className="success-message"
                            style={{ color: "green" }}
                          >
                            {success}
                          </p>
                        )}
                        <div className="submitBtn">
                          <button onClick={signupHandler}>
                            {" "}
                            {getLangLabel(langCode)?.signUp}11
                          </button>
                        </div>
                        <div className="info">
                          <p>
                            {getLangLabel(langCode)?.loginP1}{" "}
                            <span
                              onClick={async () => {
                                let url =
                                  "https://chalobharatdekhen.com/terms-and-conditions";
                                InAppBrowser.create(url, target, iaboptions);
                              }}
                            >
                              {getLangLabel(langCode)?.loginP2}
                            </span>
                            {getLangLabel(langCode)?.loginP3}
                            <span
                              onClick={async () => {
                                let url =
                                  "https://chalobharatdekhen.com/privacy-policy";
                                InAppBrowser.create(url, target, iaboptions);
                              }}
                            >
                              {getLangLabel(langCode)?.loginP4}
                            </span>
                          </p>
                        </div>
                        <div className="info">
                          <p>
                            {getLangLabel(langCode)?.loginP5}{" "}
                            <strong onClick={() => handleTabClick("login")}>
                              {getLangLabel(langCode)?.logIn}
                            </strong>
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </IonContent>
            </IonModal>
          </>
        )}
        {updateAvailable && (
          <UpdateAvailableAlert showAlert={updateAvailable} />
        )}
        {!netavailable && dataLoaded && (
          <>
            <div className="container" style={{ height: "100%" }}>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <img
                  alt="net"
                  src="./assets/icon/disconnected.png"
                  className="img-fluid"
                  style={{ height: "250px" }}
                />
                <button
                  style={{
                    marginTop: "20px",
                    backgroundColor: "#FA4939",
                    color: "white",
                    padding: "20px 40px",
                    borderRadius: "5px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => history.push("/downloads")}
                >
                  {getLangLabel(langCode)?.goToDownload}
                </button>
              </div>
            </div>
          </>
        )}
        <Footer />
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Home);
