/* eslint-disable react-hooks/exhaustive-deps */
import {
  IonHeader,
  IonContent,
  IonItem,
  IonToolbar,
  IonLabel,
  IonList,
  IonLoading,
  IonMenu,
  IonModal,
  IonMenuToggle,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { withRouter } from "react-router";
import "./Menu.css";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { Share } from "@capacitor/share";
import { getLangLabel } from "../utility/languageTranslator";
import { goStore } from "./UpdateAvailableAlert";

const Menu = ({ history }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [langCode, setlangCode] = useState("en");
  const [isFollowOpen, setIsFollowOpen] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("languageCode") || "en"
  );

  const Languageslist = [
    // { code: "", name: "Select Language" },
    { code: "en", name: "English" },
    { code: "hi", name: "Hindi" },
  ];

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language.code);
    localStorage.setItem("languageCode", language.code);
    window.location.reload();
  };

  //
  var target = "_system";
  var iaboptions = {
    location: "no", //Or 'no'
    hideurlbar: "yes", //Or 'no'
    hidenavigationbuttons: "yes",
    fullscreen: "yes",
    toolbarcolor: "#000000",
    closebuttoncaption: "exit",
    clearcache: "yes",
  };

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setlangCode(languageCode || "en");
  }, [langCode]);

  const phoneNumber = "+916289991595";
  const openWhatsApp = () => {
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };

  const mailId = "support@chalobhartdekhen.com";
  const contactUs = () => {
    const openMail = `mailto:${mailId}`;
    window.open(openMail, "_blank");
  };

  const shareApp = async () => {
    await Share?.share({
      text: `Explore India with our travel app! Discover expert tips, curated itineraries, and must-see destinations for an unforgettable journey. Download now and start your adventure!
      1. Play Store- https://play.google.com/store/apps/details?id=chalo.bharatdekhen.app
      2. Apple Store- https://apps.apple.com/in/app/bharat-dekhen/id6478861610`,
    });
  };

  const followUs = () => {
    setIsFollowOpen(true);
  };

  const closeModal = () => {
    setIsFollowOpen(false);
  };

  const openSocial = (url) => {
    window.open(url, "_blank");
  };

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent className="sideMenuBody safeArea">
        <div className="closeSideMenu">
          <IonMenuToggle key={""} autoHide={false}>
            <img alt=" " src={"./assets/icon/arrow-left.svg"} height={20} />
          </IonMenuToggle>
          <div className="logo_ctr">
            {langCode === "hi" ? (
              <img
                alt="Hindi Logo"
                src="./assets/icon/logo-hi.png"
                className="logo_width"
              />
            ) : (
              <img
                alt="Default Logo"
                src="./assets/icon/logo.png"
                className="logo_width"
              />
            )}
          </div>
          <IonMenuToggle style={{ visibility: "hidden" }}>
            <img alt=" " src={"./assets/icon/arrow-left.svg"} height={20} />
          </IonMenuToggle>
        </div>
        <div className="Menu">{getLangLabel(langCode)?.menu}</div>
        <IonList id="inbox-list">
          {
            <IonMenuToggle key={"1"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={() => history.push("/downloads")}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/bookmark.svg"} height={25} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL1}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={"2"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={goStore}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/rating.png"} height={25} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL2}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={"3"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={shareApp}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/share.svg"} height={25} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL7}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={"4"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={followUs}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/check.svg"} height={25} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL3}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={"5"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={openWhatsApp}
              >
                <div className="sidebar_img_crop">
                  <img
                    alt=" "
                    src={"./assets/icon/customer-service.png"}
                    height={25}
                  />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL4}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {
            <IonMenuToggle key={"6"} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={() => history.push("/settings")}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/settings.svg"} height={25} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL5}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          }
          {/* {
            <IonItem
              className={""}
              routerDirection="none"
              lines="none"
              detail={false}
            >
              <img
                alt=" "
                src={"./assets/icon/languages.png"}
                height={25}
                className="image_of_sidebar"
              />
              <IonLabel>
                <select
                  value={selectedLanguage}
                  onChange={(e) =>
                    handleLanguageChange(
                      Languageslist.find((lang) => lang.code === e.target.value)
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                  style={{ color: "black", border: "1px solid #cccccc", padding: "8px", width: "122px", fontSize: "12px", borderRadius: "5px", backgroundColor: "rgb(255 255 255 / 75%)" }}
                >
                  {Languageslist.map((language) => (
                    <option key={language.code} value={language.code}>
                      {language.name}
                    </option>
                  ))}
                </select>
              </IonLabel>
            </IonItem>
          }  */}
          {/* {
            <IonMenuToggle key={""} autoHide={false}>
              <IonItem
                className={""}
                routerDirection="none"
                lines="none"
                detail={false}
                onClick={() => history.push("/blogs")}
              >
                <div className="sidebar_img_crop">
                  <img alt=" " src={"./assets/icon/blog.png"} height={30} />
                </div>
                <IonLabel style={{ marginLeft: "10px" }}>
                  {getLangLabel(langCode)?.menuL2}
                </IonLabel>
              </IonItem>
            </IonMenuToggle>
          } */}

          {
            // <IonMenuToggle key={""} autoHide={false}>
            //   <IonItem
            //     className={""}
            //     routerDirection="none"
            //     lines="none"
            //     detail={false}
            //     onClick={async () => {
            //       let url = "https://chalobharatdekhen.com/privacy-policy";
            //       InAppBrowser.create(url, target, iaboptions);
            //     }}
            //   >
            //     <div className="sidebar_img_crop">
            //       <img
            //         alt=""
            //         src={"./assets/icon/privacy-policy.png"}
            //         height={30}
            //       />
            //     </div>
            //     <IonLabel style={{ marginLeft: "10px" }}>
            //       {getLangLabel(langCode)?.menuL3}
            //     </IonLabel>
            //   </IonItem>
            // </IonMenuToggle>
          }
          {
            // <IonMenuToggle key={""} autoHide={false}>
            //   <IonItem
            //     className={""}
            //     routerDirection="none"
            //     lines="none"
            //     detail={false}
            //     onClick={async () => {
            //       let url = "https://chalobharatdekhen.com/terms-and-conditions";
            //       InAppBrowser.create(url, target, iaboptions);
            //     }}
            //   >
            //     <div className="sidebar_img_crop">
            //       <img
            //         alt=" "
            //         src={"./assets/icon/terms-and-conditions.png"}
            //         height={30}
            //       />
            //     </div>
            //     <IonLabel style={{ marginLeft: "10px" }}>
            //       {getLangLabel(langCode)?.menuL4}
            //     </IonLabel>
            //   </IonItem>
            // </IonMenuToggle>
          }
          {
            // <IonMenuToggle key={""} autoHide={false}>
            //   <IonItem
            //     className={""}
            //     routerDirection="none"
            //     lines="none"
            //     detail={false}
            //     onClick={async () => {
            //       let url = "https://chalobharatdekhen.com/user-manual";
            //       InAppBrowser.create(url, target, iaboptions);
            //     }}
            //   >
            //     <div className="sidebar_img_crop">
            //       <img
            //         alt=" "
            //         src={"./assets/icon/user-guide.png"}
            //         height={30}
            //       />
            //     </div>
            //     <IonLabel style={{ marginLeft: "10px" }}>
            //       {getLangLabel(langCode)?.menuL5}
            //     </IonLabel>
            //   </IonItem>
            // </IonMenuToggle>
          }
          {
            // <IonMenuToggle key={""} autoHide={false}>
            //   <IonItem
            //     className={""}
            //     routerDirection="none"
            //     lines="none"
            //     detail={false}
            //     onClick={openWhatsApp}
            //   >
            //     <div className="sidebar_img_crop">
            //       <img alt=" " src={"./assets/icon/whatsapp.png"} height={30} />
            //     </div>
            //     <IonLabel style={{ marginLeft: "10px" }}>
            //       {getLangLabel(langCode)?.menuL6}
            //     </IonLabel>
            //   </IonItem>
            // </IonMenuToggle>
          }
          { }
        </IonList>
        <IonLoading
          cssClass="loadingCss"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
        />

        <IonModal
          isOpen={isFollowOpen}
          onDidDismiss={() => setIsFollowOpen(false)}
        >
          <IonHeader style={{ "--background": "#fff" }}>
            <IonToolbar className="t-bar">
              <div className="top-nav" style={{ background: "#fff" }}>
                <div className="nav_inner">
                  <ArrowBackIosNewRoundedIcon onClick={closeModal} />
                  <h4 className="title-txt" style={{ color: "#000" }}>
                    {getLangLabel(langCode)?.menuL3}
                  </h4>
                  <ArrowBackIosNewRoundedIcon
                    style={{ visibility: "hidden" }}
                  />
                </div>
              </div>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div className="profile-additional-options">
              <ul style={{ paddingTop: 0 }}>
                <li
                  onClick={() =>
                    openSocial(
                      "https://www.facebook.com/people/Chalo-Bharat-Dekhen/61559407614664/"
                    )
                  }
                >
                  <div className="option-menu-icon">
                    <img
                      alt=" "
                      src={"./assets/icon/facebook-app-symbol.png"}
                    />
                  </div>
                  <div className="option-menu-label">
                    {" "}
                    {getLangLabel(langCode)?.facebook}{" "}
                  </div>
                </li>
                <li
                  onClick={() => openSocial("https://twitter.com/ChaloBharat")}
                >
                  <div className="option-menu-icon">
                    <img alt=" " src={"./assets/icon/twitter.png"} />
                  </div>
                  <div className="option-menu-label">
                    {" "}
                    {getLangLabel(langCode)?.twitter}{" "}
                  </div>
                </li>
                {/* <li onClick={() => openSocial('https://www.twitter.com')}>
                  <div className="option-menu-icon">
                    <img alt=" " src={"./assets/icon/pinterest.png"} />
                  </div>
                  <div className="option-menu-label">Pinterest</div>
                </li> */}
                <li
                  onClick={() =>
                    openSocial("https://www.instagram.com/chalobharatdekhen/")
                  }
                >
                  <div className="option-menu-icon">
                    <img alt=" " src={"./assets/icon/instagram.png"} />
                  </div>
                  <div className="option-menu-label">
                    {getLangLabel(langCode)?.instagram}
                  </div>
                </li>
                <li
                  onClick={() =>
                    openSocial(
                      "https://www.linkedin.com/company/chalo-bharat-dekhen/"
                    )
                  }
                >
                  <div className="option-menu-icon">
                    <img alt=" " src={"./assets/icon/linkedin.png"} />
                  </div>
                  <div className="option-menu-label">
                    {getLangLabel(langCode)?.Linkedin}
                  </div>
                </li>
                {/* <li onClick={() => openSocial('https://www.youtube.com/channel/UC0x7YDZsnb4CA-8G73ThHuQ')}>
                  <div className="option-menu-icon">
                    <img alt=" " src={"./assets/icon/youtube.png"} />
                  </div>
                  <div className="option-menu-label">Youtube</div>
                </li> */}
              </ul>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
      <div className="sidemenuFooter">
        <div className="footerLinks">
          <IonMenuToggle
            autoHide={false}
            onClick={async () => {
              let url = "https://chalobharatdekhen.com/privacy-policy";
              InAppBrowser.create(url, target, iaboptions);
            }}
          >
            {" "}
            {getLangLabel(langCode)?.footerL2}
          </IonMenuToggle>
          <IonMenuToggle
            autoHide={false}
            onClick={async () => {
              let url = "https://chalobharatdekhen.com/terms-and-conditions";
              InAppBrowser.create(url, target, iaboptions);
            }}
          >
            {getLangLabel(langCode)?.footerL3}
          </IonMenuToggle>
          <IonMenuToggle
            autoHide={false}
            onClick={async () => {
              let url = "https://chalobharatdekhen.com/user-manual";
              InAppBrowser.create(url, target, iaboptions);
            }}
          >
            {getLangLabel(langCode)?.footerL4}
          </IonMenuToggle>
        </div>
        <IonLabel
          style={{
            textAlign: "center",
            whiteSpace: "normal",
            color: "rgb(0 0 0 / 60%)",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          {getLangLabel(langCode)?.menuFooter}
        </IonLabel>
        <div className="underline"></div>
      </div>
    </IonMenu>
  );
};

export default withRouter(Menu);
