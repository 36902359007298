import { useEffect, useState } from "react";
import { getLangLabel } from "../../utility/languageTranslator";
import eyeOpen from "../../images/eye-open.png";
import eyeClose from "../../images/eye-close.png";
import { InAppBrowser } from "@awesome-cordova-plugins/in-app-browser";
import { useParams, withRouter } from "react-router";
import { APIFY, HTTP_CLIENT } from "../../services/Api";
import { IonAlert } from "@ionic/react";

const ForgotPassword = () => {
    const { id, token } = useParams();
    const [tokenString, setTokenString] = useState(token);
    const [langCode, setlangCode] = useState("en");
    const [oldPassword, setOldPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [passView, setPassView] = useState(false);
    const [confirmPassView, setConfirmPassView] = useState(false);
    const [passwordError, setPasswordError] = useState("");
    const [user, setUser] = useState({});
    const [paramId, setParamId] = useState(id);
    var target = "_system";
    var iaboptions = {
        location: "no", //Or 'no'
        hideurlbar: "yes", //Or 'no'
        hidenavigationbuttons: "yes",
        fullscreen: "yes",
        toolbarcolor: "#000000",
        closebuttoncaption: "exit",
        clearcache: "yes",
    };

    useEffect(() => {
        console.log(paramId)
        HTTP_CLIENT(APIFY(`/users/${paramId}`), "GET").then((res) => {
            setUser(res.data);
        }).catch((err) => {
            console.log("error", err);
        })
    }, [])
    const handleForgotPassword = async () => {
        console.log(user)
        try {
            if(!newPassword || !confirmPassword) {
                alert(getLangLabel(langCode)?.passRequired);
                return;
            }
            else if (newPassword !== confirmPassword) {
                alert(getLangLabel(langCode)?.passwordDidNotMatch);
                return;
            }
            else {
                HTTP_CLIENT(APIFY("/users/validate-token"), "POST", {
                    token: tokenString,
                }).then((res) => {
                    console.log(res)
                    const currentTimestamp = Math.floor(Date.now() / 1000);
                    console.log(currentTimestamp, currentTimestamp > res.exp)
                    if (currentTimestamp > res.exp) {
                        throw new Error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                })
                HTTP_CLIENT(APIFY(`/users/reset-password`), "PUT", { paramId: paramId, new_password: confirmPassword })
                    .then((res) => {
                        alert(getLangLabel(langCode)?.passResetSuccessfully);
                    })
                    .catch(({ response }) => {
                        const msg = response?.data?.errors[0];
                        console.log(response);
                        console.log(msg);
                    });

            }
        } catch (error) {
            console.error("Password Change failed:", error);
        }
    }
    const togglePassView = () => {
        setPassView(!passView);
    };

    const toggleConfirmPassView = () => {
        setConfirmPassView(!confirmPassView);
    };
    return <div className="tabContent">
        <div className="verifyLogin">
            <h4 className="fields">Reset Password</h4>
            <div className="fields">
                <label>{getLangLabel(langCode)?.newPassword}</label>
                <div className="password-container">
                    <input
                        type={passView ? "text" : "password"}
                        placeholder={
                            getLangLabel(langCode)?.yourPasswordP
                        }
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <span onClick={togglePassView}>
                        <img
                            alt=""
                            src={passView ? eyeClose : eyeOpen}
                            width={20}
                        />
                    </span>
                    {passwordError && (
                        <p className="error-message">{passwordError}</p>
                    )}
                </div>
            </div>
            <div className="fields">
                <label>{getLangLabel(langCode)?.confirmPassword}</label>
                <div className="password-container">
                    <input
                        type={confirmPassView ? "text" : "password"}
                        placeholder={
                            getLangLabel(langCode)?.yourPasswordP
                        }
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span onClick={toggleConfirmPassView}>
                        <img
                            alt=""
                            src={confirmPassView ? eyeClose : eyeOpen}
                            width={20}
                        />
                    </span>
                    {passwordError && (
                        <p className="error-message">{passwordError}</p>
                    )}
                </div>
            </div>
            {/* <div className="forgotPass">Forgot Password?</div> */}

            <div className="submitBtn" onClick={handleForgotPassword}>
                <button> {getLangLabel(langCode)?.logIn}</button>
            </div>
        </div>
    </div>
};

export default withRouter(ForgotPassword); 