import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  useIonViewDidLeave,
  IonSkeletonText,
} from "@ionic/react";
import { withRouter } from "react-router";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useParams } from "react-router-dom";
import { FirebaseAnalytics } from "@capacitor-firebase/analytics";
import DesktopHeader from "../../components/DesktopHeader";
import { removeTagsAndStyles } from "../../utility/commonMethods";
import FooterMenu from "../../components/FooterMenu";
import MyTextToSpeach from "../../components/MyTextToSpeach";
import { getCityInLang, getLangState } from "../../utility/languageTranslator";

function DisplayContent({ content }) {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
}

const SpecialEventDetails = () => {
  const history = useHistory();
  const { placeId } = useParams();
  const [eventdetails, setEventDetails] = useState({});
  const [imgdata, setImgdata] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [speech, setSpeech] = useState("");
  const [langCode, setLangCode] = useState("en");
  const [loadingImages, setLoadingImages] = useState([]);
  const [toolbarBg, setToolbarBg] = useState("rgba(255, 255, 255, 0)");
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [toolbarShadow, setToolbarShadow] = useState("none");
  const [iconColor, setIconColor] = useState("rgba(255, 255, 255, 1)");

  const checkIsDesktop = () => {
    setIsDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    const languageCode = localStorage.getItem("languageCode");
    setLangCode(languageCode || "en");
  }, []);

  useEffect(() => {
    const eventPlace = JSON.parse(localStorage.getItem("placeDetails")) || [];
    setEventDetails(eventPlace);
    setImgdata(eventPlace?.img[0]);
    if (eventPlace?.img) {
      setLoadingImages(Array(eventPlace?.img?.length).fill(true));
    }
  }, [placeId]);

  const setAnalyticsScreen = async () => {
    try {
      await FirebaseAnalytics.setCurrentScreen({
        screenName: `Special Event Details: ${eventdetails?.name}, ${eventdetails?.city}, ${eventdetails?.state}, ${eventdetails?.eventLabel}`,
        screenClass: "Special Event Details",
      });
      await FirebaseAnalytics.logEvent({
        name: "screen_view",
        params: {
          screen_name: `Special Event Details: ${eventdetails?.name}, ${eventdetails?.city}, ${eventdetails?.state}, ${eventdetails?.eventLabel}`,
          screen_class: "Special Event Details",
          additional_context: {
            place: eventdetails?.name,
            state: eventdetails?.state,
            city: eventdetails?.city,
            label: eventdetails?.eventLabel,
          },
        },
      });
      console.log("Screen view event logged");
    } catch (error) {
      console.error("Error logging screen view event:", error);
    }
  };
  useEffect(() => {
    if (eventdetails?.name) setAnalyticsScreen();
  }, [eventdetails?.name]);

  const handleImageLoad = (index) => {
    const newLoadingImages = [...loadingImages];
    newLoadingImages[index] = false;
    setLoadingImages(newLoadingImages);
  };
  const responsiveimg = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  useEffect(() => {
    const eventDetails =
      removeTagsAndStyles(
        eventdetails?.eventdetails_lang?.[langCode]
          ? eventdetails?.eventdetails_lang?.[langCode]
          : eventdetails?.eventdetails
      ) || "";
    setSpeech(eventDetails);
    checkIsDesktop();
    window.addEventListener("resize", checkIsDesktop);
    return () => {
      window.removeEventListener("resize", checkIsDesktop);
    };
  }, [eventdetails]);

  const handleScroll = (event) => {
    const scrollTop = event.detail.scrollTop;
    const maxScroll = 50;
    const newOpacity = Math.min(1, scrollTop / maxScroll);

    setToolbarBg(`rgba(255, 255, 255, ${newOpacity})`);
    const newTextColor = `rgba(${255 * (1 - newOpacity)}, ${
      255 * (1 - newOpacity)
    }, ${255 * (1 - newOpacity)}, 1)`;
    setIconColor(newTextColor);
    setLastScrollTop(scrollTop);

    if (scrollTop > 50) {
      setToolbarShadow("rgb(136 136 136 / 28%) 0px 4px 6px");
    } else {
      setToolbarShadow("none");
    }
  };

  return (
    <IonPage>
      <IonHeader style={{ "--background": "#fff" }}>
        <IonToolbar
          className="transparentToolbar t-bar"
          style={{
            background: toolbarBg,
            transition: "0.3s ease",
            boxShadow: toolbarShadow,
          }}
        >
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon
                style={{ color: iconColor }}
                className=""
                onClick={() => history.goBack()}
              />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      <IonContent
        fullscreen
        onIonScroll={handleScroll}
        scrollEvents={true}
        className="backgroundGraphics"
      >
        <div className="columnContainer">
          <div className="bannerLeft">
            <img src={imgdata} alt="Event" />
            <div className="labelDiv">
              {eventdetails?.name_lang?.[langCode]
                ? eventdetails?.name_lang?.[langCode]
                : eventdetails?.name}
            </div>
          </div>
          <div className="bg-grey">
            <div className="img-card">
              <div className="container">
                <div
                  className="hasBannerOverlay"
                  style={{ position: "relative" }}
                >
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsiveimg}
                    ssr={true}
                    infinite={false}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    customTransition="all 1.2s ease 0s"
                    transitionDuration={1000}
                    containerClass="carousel-container carousel-list"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                    slidesToSlide={1}
                    arrows={true}
                  >
                    {eventdetails?.img?.length > 0 ? (
                      eventdetails?.img?.map((item, index) => (
                        <div key={index}>
                          {loadingImages[index] && (
                            <IonSkeletonText
                              animated
                              style={{ width: "100%", height: "280px" }}
                            />
                          )}
                          <img
                            alt=" "
                            src={item}
                            style={{
                              height: "280px",
                              width: "100%",
                              display: loadingImages[index] ? "none" : "block",
                            }}
                            onLoad={() => handleImageLoad(index)}
                          />
                        </div>
                      ))
                    ) : (
                      <div>
                        <img alt=" " src="./assets/icon/Districtbg.jpg" />
                      </div>
                    )}
                  </Carousel>
                </div>
                <div className="districtName">
                  <h4 onClick={() => history.push("/citylist")}>
                    {langCode === "en"
                      ? eventdetails?.state
                      : getLangState()?.[eventdetails?.state]}
                  </h4>

                  <h4
                    onClick={() => history.push("/particularplaceinformation")}
                    style={{ marginLeft: "10px" }}
                  >
                    {langCode === "en"
                      ? eventdetails?.city
                      : getCityInLang(eventdetails?.city, langCode)}
                  </h4>
                </div>
                <div style={{ padding: "20px" }}>
                  <div className="d_card historyArea">
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <h4
                        className="forMbl"
                        style={{ fontSize: "15px", fontWeight: "bold" }}
                      >
                        {eventdetails?.name_lang?.[langCode]
                          ? eventdetails?.name_lang?.[langCode]
                          : eventdetails?.name}
                      </h4>
                      <div
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        <MyTextToSpeach speech={speech} langCode={langCode} />
                      </div>
                    </div>
                    <DisplayContent
                      content={
                        eventdetails?.eventdetails_lang?.[langCode]
                          ? eventdetails?.eventdetails_lang?.[langCode]
                          : eventdetails?.eventdetails
                      }
                    />
                  </div>
                  {(eventdetails?.timing_mon_fri_from &&
                    eventdetails?.timing_mon_fri_to) ||
                  (eventdetails?.timing_sat_sun_from &&
                    eventdetails?.timing_sat_sun_to) ? (
                    <h5>Timing</h5>
                  ) : null}
                  {eventdetails?.timing_mon_fri_from &&
                    eventdetails?.timing_mon_fri_to && (
                      <p>
                        Monday-Friday :{" "}
                        {`${eventdetails?.timing_mon_fri_from}-${eventdetails?.timing_mon_fri_to}`}
                      </p>
                    )}
                  {eventdetails?.timing_sat_sun_from &&
                    eventdetails?.timing_sat_sun_to && (
                      <p>
                        Saturday-Sunday :{" "}
                        {`${eventdetails?.timing_sat_sun_from}-${eventdetails?.timing_sat_sun_to}`}
                      </p>
                    )}
                  {(eventdetails?.ticketAdult || eventdetails?.ticketChild) && (
                    <h5>Ticket price</h5>
                  )}
                  {eventdetails?.ticketAdult && (
                    <p>Adult : Rs {eventdetails?.ticketAdult}/Person</p>
                  )}
                  {eventdetails?.ticketChild && (
                    <p>Children : Rs {eventdetails?.ticketChild}/Child</p>
                  )}
                  {eventdetails?.bestRoute && (
                    <>
                      <h5>Best Route</h5>
                      <p>{eventdetails?.bestRoute}</p>
                    </>
                  )}
                  {eventdetails?.timeToVisit && (
                    <>
                      <h5>Best time to visit</h5>
                      <p>{eventdetails?.timeToVisit}</p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <FooterMenu history={history} langCode={langCode} />
    </IonPage>
  );
};

export default withRouter(SpecialEventDetails);
