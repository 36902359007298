export const districtDataTypesData = [
    {
        "_id": "66d2eb6cda98000d8dfa1241",
        "title": "top",
        "key": "top",
        "order": 1,
        "createdAt": "2024-08-31T10:07:40.244Z",
        "updatedAt": "2024-08-31T10:07:40.244Z"
    },
    {
        "_id": "66d2eca3da98000d8dfa1245",
        "title": "Overview",
        "key": "history",
        "order": 2,
        "createdAt": "2024-08-31T10:12:51.220Z",
        "updatedAt": "2024-08-31T10:12:51.220Z",
        "title_lang": {
            "hi": "संक्षिप्त विवरण"
        }
    },
    {
        "_id": "66d2edacda98000d8dfa1249",
        "title": "Nearest Airport",
        "key": "nearest-airports",
        "order": 3,
        "createdAt": "2024-08-31T10:17:16.574Z",
        "updatedAt": "2024-08-31T10:17:16.574Z",
        "title_lang": {
            "hi": "निकटतम हवाई अड्डा"
        }
    },
    {
        "_id": "66d2ed21da98000d8dfa1247",
        "title": "Nearest Shipping Dock",
        "key": "nearest-shipping-dock",
        "order": 4,
        "createdAt": "2024-08-31T10:14:57.520Z",
        "updatedAt": "2024-08-31T10:14:57.520Z",
        "title_lang": {
            "hi": "निकटतम शिपिंग डॉक"
        }
    },
    {
        "_id": "66d542f7bff7f06b9329fa67",
        "title": "Nearest Railway Station",
        "key": "nearest-railway-station",
        "order": 5,
        "createdAt": "2024-09-02T04:45:43.253Z",
        "updatedAt": "2024-09-02T04:45:43.253Z",
        "title_lang": {
            "hi": "निकटतम रेलवे स्टेशन"
        }
    },
    {
        "_id": "66d545d6bff7f06b9329fa7d",
        "title": "Local Festivals",
        "key": "famous-festivals",
        "order": 6,
        "createdAt": "2024-09-02T04:57:58.732Z",
        "updatedAt": "2024-09-02T04:57:58.732Z",
        "title_lang": {
            "hi": "प्रसिद्ध त्यौहार"
        }
    },
    {
        "_id": "66d2ec3fda98000d8dfa1243",
        "title": "Optional Sights",
        "key": "optional-sights",
        "order": 7,
        "createdAt": "2024-08-31T10:11:11.651Z",
        "updatedAt": "2024-08-31T10:11:11.651Z",
        "title_lang": {
            "hi": "वैकल्पिक जगहें"
        }
    },
    {
        "_id": "66d543febff7f06b9329fa6d",
        "title": "Popular Dishes",
        "key": "famous-food",
        "order": 8,
        "createdAt": "2024-09-02T04:50:06.618Z",
        "updatedAt": "2024-09-02T04:50:06.618Z",
        "title_lang": {
            "hi": "लोकप्रिय व्यंजन"
        }
    },
    {
        "_id": "66f10189d9f78c48692e2061",
        "title": "Popular Dishes",
        "key": "popular-dishes",
        "order": 8,
        "createdAt": "2024-09-23T05:50:01.656Z",
        "updatedAt": "2024-09-23T05:50:01.656Z",
        "title_lang": {
            "hi": "लोकप्रिय व्यंजन"
        }
    },
    {
        "_id": "66d5442fbff7f06b9329fa6f",
        "title": "Shopping",
        "key": "shopping",
        "order": 9,
        "createdAt": "2024-09-02T04:50:55.393Z",
        "updatedAt": "2024-09-02T04:50:55.393Z",
        "title_lang": {
            "hi": "खरीदारी"
        }
    },
    {
        "_id": "66d5458ebff7f06b9329fa7b",
        "title": "Best Time to Visit",
        "key": "best-time-to-visit",
        "order": 9,
        "createdAt": "2024-09-02T04:56:46.858Z",
        "updatedAt": "2024-09-02T04:56:46.858Z",
        "title_lang": {
            "hi": "यात्रा का सर्वोत्तम समय"
        }
    },
    {
        "_id": "66d2ef06da98000d8dfa1251",
        "title": "Restaurants & Cafes",
        "key": "restaurants-and-cafes",
        "order": 10,
        "createdAt": "2024-08-31T10:23:02.146Z",
        "updatedAt": "2024-08-31T10:23:02.146Z",
        "title_lang": {
            "hi": "रेस्तरां और कैफ़े"
        }
    },
    {
        "_id": "66d2edfada98000d8dfa124b",
        "title": "Main Hotels",
        "key": "main-hotels",
        "order": 11,
        "createdAt": "2024-08-31T10:18:34.716Z",
        "updatedAt": "2024-08-31T10:18:34.716Z",
        "title_lang": {
            "hi": "शीर्ष होटल"
        }
    },
    {
        "_id": "66d54531bff7f06b9329fa77",
        "title": "Bars & Clubs",
        "key": "bars-and-clubs",
        "order": 11,
        "createdAt": "2024-09-02T04:55:13.643Z",
        "updatedAt": "2024-09-02T04:55:13.643Z",
        "title_lang": {
            "hi": "बार और क्लब"
        }
    },
    {
        "_id": "66d2ee5bda98000d8dfa124d",
        "title": "Car Rentals",
        "key": "car-rentals",
        "order": 12,
        "createdAt": "2024-08-31T10:20:11.786Z",
        "updatedAt": "2024-08-31T10:20:11.786Z",
        "title_lang": {
            "hi": "कार का किराया"
        }
    },
    {
        "_id": "66d5455ebff7f06b9329fa79",
        "title": "Activities",
        "key": "activities",
        "order": 13,
        "createdAt": "2024-09-02T04:55:58.603Z",
        "updatedAt": "2024-09-02T04:55:58.603Z"
    },
    {
        "_id": "673c3165f160d7de192a104b",
        "title": "Tourist Information Centre",
        "key": "tourist-information",
        "order": 14,
        "createdAt": "2024-11-19T06:34:13.307Z",
        "updatedAt": "2024-11-19T06:34:13.307Z",
        "title_lang": {
            "hi": "पर्यटक सूचना केंद्र"
        }
    },
    {
        "_id": "66d2ef8bda98000d8dfa1253",
        "title": "District Website",
        "key": "district-website",
        "order": 17,
        "createdAt": "2024-08-31T10:25:15.774Z",
        "updatedAt": "2024-08-31T10:25:15.774Z",
        "title_lang": {
            "hi": "जिला वेबसाइट"
        }
    },
    {
        "_id": "66d2efbada98000d8dfa1255",
        "title": "District Map",
        "key": "district-map",
        "order": 18,
        "createdAt": "2024-08-31T10:26:02.449Z",
        "updatedAt": "2024-08-31T10:26:02.449Z",
        "title_lang": {
            "hi": "जिला मानचित्र"
        }
    },
    {
        "_id": "66d2f00bda98000d8dfa1257",
        "title": "State Map",
        "key": "state-map",
        "order": 19,
        "createdAt": "2024-08-31T10:27:23.232Z",
        "updatedAt": "2024-08-31T10:27:23.232Z",
        "title_lang": {
            "hi": "राज्य मानचित्र"
        }
    },
    {
        "_id": "66d5448cbff7f06b9329fa73",
        "title": "Bottom",
        "key": "bottom",
        "order": 20,
        "createdAt": "2024-09-02T04:52:28.343Z",
        "updatedAt": "2024-09-02T04:52:28.343Z",
        "title_lang": {
            "hi": "तल"
        }
    },
    {
        "_id": "66d2f074da98000d8dfa1259",
        "title": "Website Top",
        "key": "website-top",
        "order": 21,
        "createdAt": "2024-08-31T10:29:08.777Z",
        "updatedAt": "2024-08-31T10:29:08.777Z"
    }
]