import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonHeader,
  IonContent,
  IonToolbar,
  IonModal,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { getStateList } from "../../modalconfig";
import DesktopHeader from "../../components/DesktopHeader";
import remove from "../../images/remove.png";
import { getLangState } from "../../utility/languageTranslator";
import FooterMenu from "../../components/FooterMenu";

const City = ({ history }) => {
  const [cityList, setCityList] = useState([]);
  const [stateName, setStateName] = useState("");
  const [stateIconName, setStateIconName] = useState("");
  const [isDesktop, setIsDesktop] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const [langCode, setLangCode] = useState("en");
  const checkIsDesktop = () => {
    setIsDesktop(window?.innerWidth > 1024 || false);
  };

  useEffect(() => {
    if (
      location.pathname === "/placeinformation" ||
      location.pathname === "/particularplaceinformation"
    ) {
      setIsOpen(false);
      return null;
    }

    if (
      location.pathname === "/cityList" ||
      (location.pathname === "/home" && isOpen) ||
      !isDesktop
    ) {
      setIsOpen(true);
      const fetchData = async () => {
        const languageCode = localStorage.getItem("languageCode");
        setLangCode(languageCode || "en");
        const details = JSON.parse(localStorage.getItem("placeDetails"));
        const state =
          languageCode === "en"
            ? details?.state
            : getLangState()?.[details?.state];
        setStateName(state);
        setStateIconName(details?.state);
        checkIsDesktop();
        const res = await getStateList({ state: details?.state });
        setCityList(res?.city || []);
      };
      fetchData();

      window.addEventListener("resize", checkIsDesktop);
      return () => {
        window.removeEventListener("resize", checkIsDesktop);
      };
    }
  }, [location.key]);

  const handleGoBack = () => {
    history.goBack();
  };

  const renderContent = () => (
    <IonContent className="backgroundGraphics">
      <div className="">
        <div className="flex-head">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 20px 0 20px",
            }}
          >
            <h4 className="inner-title">{stateName || ""}</h4>
            <img
              src={remove}
              width={40}
              height={40}
              onClick={() => {
                setIsOpen(false);
                setStateName("");
                setCityList([]);
                history.push("/home");
              }}
              alt=""
            />
          </div>
        </div>
        <div className="columnContainer">
          <div className="bg-grey">
            <div className="placeList">
              <ul>
                {cityList?.map((item, index) => (
                  <li
                    key={item?._id}
                    onClick={async () => {
                      let details = JSON.parse(
                        localStorage.getItem("placeDetails")
                      );
                      let updated_details = {
                        ...details,
                        city: item?.city || "",
                      };
                      localStorage.setItem(
                        "placeDetails",
                        JSON.stringify(updated_details)
                      );
                      setIsOpen(false);
                      setStateName("");
                      setCityList([]);
                      history.push(`/placeinformation`);
                    }}
                  >
                    <label>{index + 1}.</label>
                    <label>
                      {(() => {
                        const cityName =
                          item?.city_lang?.[langCode] || item?.city;

                        if (cityName.includes("⭐")) {
                          const [nameWithoutStar, star] = cityName.split("⭐");

                          return (
                            <>
                              {nameWithoutStar}
                              <span style={{ marginLeft: "auto" }}>⭐</span>
                            </>
                          );
                        }

                        return cityName;
                      })()}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </IonContent>
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="t-bar" style={{ "--background": "#fff" }}>
          <div className="top-nav">
            <div className="nav_inner">
              <ArrowBackIosNewRoundedIcon className="" onClick={handleGoBack} />
              <h3
                style={{
                  textAlign: "center",
                  fontWeight: "500",
                  color: "",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontSize: "14px",
                  margin: 0,
                  textTransform: "uppercase"
                }}
              >
                <img
                  alt=" "
                  src={`./assets/state-icons/${stateIconName}.png`}
                  height={30}
                />{" "}
                {stateName || ""}
              </h3>
              <ArrowBackIosNewRoundedIcon className="visibilityHidden" />
            </div>
          </div>
        </IonToolbar>
        <DesktopHeader />
      </IonHeader>
      {isDesktop ? (
        <IonModal isOpen={isOpen}>{renderContent()}</IonModal>
      ) : (
        <>
          {renderContent()}
          <FooterMenu history={history} langCode={langCode} />
        </>
      )}
    </IonPage>
  );
};

export default withRouter(City);
